import primitives from '@veneer/primitives';
import styled from 'styled-components';
import { breakpointsHelper } from '../../../../../common/utils/breakpointsHelper';
interface ContainerProps {
  isLastChild?: boolean;
}
export const Container = styled.div<ContainerProps>`
  && {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: ${primitives.color.gray0};
    border-radius: ${(props) =>
      props.isLastChild ? '0px 0px 12px 12px' : '0px'};
  }
`;

export const ItemsContainer = styled.div<ContainerProps>`
  && {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: ${(props) =>
      props.isLastChild ? '0px' : `1px solid ${primitives.color.gray2}`};
    margin-right: 56px;
    padding-bottom: 8px;

    @media (max-width: ${breakpointsHelper.large_max_1199px}) {
      width: auto;
      flex-direction: column;
      padding-bottom: 0px;
    }

    @media (max-width: 715px) {
      margin-right: 0px;
    }
  }
`;
export const AttributesContainer = styled.div<ContainerProps>`
  && {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    border-radius: ${(props) =>
      props.isLastChild ? '0px 0px 12px 0px' : '0px'};

    @media (max-width: ${breakpointsHelper.large_max_1199px}) {
      width: auto;
      flex-direction: column;
    }

    @media (max-width: 715px) {
      width: 100%;
    }
  }
`;
