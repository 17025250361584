import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { breakpointsHelper } from '../../../common/utils/breakpointsHelper';
import Button from '@veneer/core/dist/scripts/button';

export const YearlyPlanCard = styled.div`
  && {
    cursor: 'none';
    pointer-events: 'none';
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    height: 264px;
    width: 200px;
    border-radius: 8px;
    border: 1px solid ${primitives.color.gray3};
    margin-right: 12px;

    @media (max-width: ${breakpointsHelper.medium_min_768px}) {
      flex-direction: column;
      width: 100%;
      height: 100%;
      min-height: 172px;
    }
  }
`;

export const PlaceHolder = styled.div`
  & {
    margin-bottom: 25px;

    @media (max-width: ${breakpointsHelper.medium_min_768px}) {
      margin-bottom: 0;
    }
  }
`;

export const CardContent = styled.div`
  & {
    display: flex;
    flex-direction: column;

    @media (min-width: ${breakpointsHelper.small_min_576px}) and (max-width: ${breakpointsHelper.medium_min_768px}) {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }

    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      margin-bottom: 16px;
    }
  }
`;

export const SmallFont = styled.span`
  & {
    font-size: 12px;
  }
`;

export const CardText = styled.div`
  && {
    margin-bottom: 24px;
    margin-top: 8px;
    line-height: 20px;
    font-size: 14px;

    @media (min-width: ${breakpointsHelper.small_min_576px}) and (max-width: ${breakpointsHelper.medium_min_768px}) {
      margin-bottom: 0;
    }
  }
`;

export const SaveText = styled.p`
  &&& {
    color: ${primitives.color.cornFlowerBlue8};
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;

    @media (max-width: ${breakpointsHelper.medium_min_768px}) {
      margin-top: 4px;
    }
  }
`;

export const LargeFont = styled.span`
  && {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }
`;

export const YearlyPlanContainer = styled.div`
  && {
    padding: 16px;
  }
`;

export const PriceStrike = styled.span`
  && {
    position: relative;
    text-decoration: none;
  }
  &&::after {
    content: '';
    position: absolute;
    top: 48%;
    left: 0;
    width: 100%;
    height: 2px;
    background: ${primitives.color.cornFlowerBlue7};
    transform: rotate(9.82deg);
  }
`;

export const ButtonArea = styled.div`
  && {
    @media (min-width: ${breakpointsHelper.small_min_576px}) and (max-width: ${breakpointsHelper.medium_min_768px}) {
      display: flex;
      align-items: flex-end;
    }
  }
`;

export const SelectPlanButton = styled(Button)`
  && {
    width: 100%;
    border-radius: 8px;

    @media (min-width: ${breakpointsHelper.small_min_576px}) and (max-width: ${breakpointsHelper.medium_min_768px}) {
      width: fit-content;
    }
  }
`;

export const SelectPlanButtonText = styled.p`
  && {
    line-height: 0;
    padding: 8px 16px;
  }
`;
