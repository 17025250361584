import React from 'react';
import Modal from '@veneer/core/dist/scripts/modal';
import { ConsumerPlanSelector } from '../../../../V2/ConsumerPlanSelector';
import { useDispatch, usePlanSelectorV3State } from '../../../hooks';
import { PlanSelectorV3Props } from '../../../types/planSelectorV3Props';

export const PlansModal: React.FC<PlanSelectorV3Props> = (props) => {
  const { showPlansModal } = usePlanSelectorV3State();
  const dispatch = useDispatch();

  const hidePlansModal = () => {
    if (dispatch) {
      dispatch({ type: 'SHOW_PLANS_MODAL', showPlansModal: false });
    }
  };
  return (
    <Modal
      closeButton
      maxWidth={940}
      show={showPlansModal}
      onClose={() => hidePlansModal()}
    >
      <ConsumerPlanSelector {...props} showPlanProgramTabs program="i_toner" />
    </Modal>
  );
};
