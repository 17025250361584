import React from 'react';
import IconPriceTag from '@veneer/core/dist/scripts/icons/icon_price_tag';
import Button from '@veneer/core/dist/scripts/button';
import primitives from '@veneer/primitives';
import {
  YearlyCardContainer,
  ButtonContainer,
  TitleContainer,
  Description,
  ImageContainer,
  YearlyImage,
  NewTag,
  IconPriceTagContainer,
  NewTagContent
} from './styles';
import { useGetText } from '../../hooks/useGetText';
import { useDashboardPlansState } from '../../hooks/useDashboardPlansState';

export const YearlyCard: React.FC = () => {
  const getText = useGetText();
  const { onYearlyCardClick } = useDashboardPlansState();

  return (
    <>
      <YearlyCardContainer
        data-testid="dashboard-plan-selector-yearly-card-container"
        onClick={onYearlyCardClick}
      >
        <ImageContainer>
          <YearlyImage />
        </ImageContainer>
        <NewTag>
          <NewTagContent>
            <IconPriceTagContainer>
              <IconPriceTag color={primitives.color.white} size={16} />
            </IconPriceTagContainer>
            <p>{getText('plans_selector.yearly_card.new_tag')}</p>
          </NewTagContent>
        </NewTag>
        <TitleContainer>
          <h1 data-testid="dashboard-plan-selector-yearly-card-title">
            {getText('plans_selector.yearly_card.freedom_print')}
          </h1>
        </TitleContainer>
        <Description
          data-testid="dashboard-plan-selector-yearly-card-description"
          dangerouslySetInnerHTML={{
            __html: getText('plans_selector.yearly_card.card_content')
          }}
        />
        <ButtonContainer>
          <Button
            data-testid="yearly-plans-compare"
            data-linkid="ii_plans"
            data-analytics-id="ii_plans"
          >
            {getText('plans_selector.yearly_card.compare_yearly')}
          </Button>
        </ButtonContainer>
      </YearlyCardContainer>
    </>
  );
};
