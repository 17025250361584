import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const Container = styled.div`
  && {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
`;

export const PlansContainer = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    @media (max-width: 970px) {
      flex-direction: column;
    }
  }
`;

interface SubtitleContainerProps {
  isInsideTab: boolean;
}
export const SubtitleContainer = styled.div<SubtitleContainerProps>`
  && {
    display: inline;
    align-items: center;
    padding-right: 20px;
    margin-top: ${({ isInsideTab }) => (isInsideTab ? '32px' : '12px')};
    margin-bottom: 16px;

    span {
      font-family: ${primitives.typography.family0};
      color: ${primitives.color.gray12};
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-right: 4px;
    }
  }
`;

export const MoreInfo = styled.span`
  &&& {
    font-family: ${primitives.typography.family0};
    color: ${primitives.color.hpBlue7};
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      color: ${primitives.color.hpBlue8};
      text-decoration: underline;
    }
  }
`;
