import { localeToStringsJson } from '../../assets/locale';
import { DEFAULT_COUNTRY, DEFAULT_LANGUAGE } from '../constants';

export function supportedCountries(locales) {
  const countries = [];

  Object.keys(locales).forEach(function (key) {
    const countryParts = key.split('_');
    const country = countryParts[1];

    if (!countries.includes(country)) {
      countries.push(country);
    }
  });

  return countries;
}

export function setFallbackLocale(language: string, country: string) {
  const locale = language + '_' + country;
  if (!supportedCountries(localeToStringsJson).includes(country)) {
    language = DEFAULT_LANGUAGE;
    country = DEFAULT_COUNTRY;
  } else if (!(locale in localeToStringsJson)) {
    const filteredResults = [];
    Object.keys(localeToStringsJson).forEach(function (key) {
      const countryParts = key.split('_');
      if (country === countryParts[1]) {
        filteredResults.push({
          language: countryParts[0],
          country: countryParts[1]
        });
      }
    });
    if (filteredResults.length > 0) return filteredResults[0];
  }
  return { language, country };
}
