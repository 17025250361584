import React from 'react';
import GiftIcon from '@veneer/core/dist/scripts/icons/icon_gift';

import {
  HighlightedInfoContainer,
  TrialInfoContent,
  TrialInfoContainer,
  IconContainer
} from './styles';
import { useConsumerPlansState } from '../../../../../../hooks/useConsumerPlansState';
import { useGetText } from '../../../../../../hooks/useGetText';

export const TrialInfo: React.FC = () => {
  const { program, selectedPlan } = useConsumerPlansState();
  const getText = useGetText();
  const isInkPaper = selectedPlan?.isInkPaperPlan;

  const trialInfoContent = () => {
    if (isInkPaper) {
      if (selectedPlan.plan.freeMonths && selectedPlan.plan.paperFreeMonths) {
        return getText(
          'plans_selector.modals.this_plan_includes_a_x_month_trial_of_ink_and_a_x_month_trial_of_paper',
          {
            inkTrialMonths: `${selectedPlan.plan.freeMonths}`,
            paperTrialMonths: `${selectedPlan.plan.paperFreeMonths}`
          }
        );
      } else if (selectedPlan.plan.freeMonths) {
        return getText(
          'plans_selector.modals.this_plan_includes_a_x_month_trial_of_ink',
          {
            inkTrialMonths: `${selectedPlan.plan.freeMonths}`
          }
        );
      } else if (selectedPlan.plan.paperFreeMonths) {
        return getText(
          'plans_selector.modals.this_plan_includes_a_x_month_trial_of_paper',
          {
            paperTrialMonths: `${selectedPlan.plan.paperFreeMonths}`
          }
        );
      }
    } else if (program === 'i_ink' && selectedPlan.plan.freeMonths) {
      return getText(
        'plans_selector.modals.this_plan_includes_a_x_month_trial_of_ink',
        {
          inkTrialMonths: `${selectedPlan.plan.freeMonths}`
        }
      );
    } else if (program === 'i_toner' && selectedPlan.plan.freeMonths) {
      return getText(
        'plans_selector.modals.this_plan_includes_a_x_month_trial_of_toner',
        {
          tonerTrialMonths: `${selectedPlan.plan.freeMonths}`
        }
      );
    }
  };
  return (
    <>
      {trialInfoContent() && (
        <HighlightedInfoContainer>
          <TrialInfoContainer program={selectedPlan.plan.program}>
            <IconContainer program={selectedPlan.plan.program}>
              <GiftIcon size={12} color="white" />
            </IconContainer>
            <TrialInfoContent
              dangerouslySetInnerHTML={{
                __html: trialInfoContent() || ''
              }}
            />
          </TrialInfoContainer>
        </HighlightedInfoContainer>
      )}
    </>
  );
};
