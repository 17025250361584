import React from 'react';
import { PlanCard } from '../PlanCard';
import { CompareCardContainer } from './styles';
import {
  parseCurrency,
  formatCurrency
} from '../../../common/lib/moneyHelpers';
import { useYearlyPlanSelectorState } from '../../hooks/useYearlyPlanSelectorState';

export const SwitchMonthlyToYearlySection: React.FC = () => {
  const { correspondingYearlyPlan, currentPlan } = useYearlyPlanSelectorState();

  const calculateYearlyPriceWithoutSavings = (monthlyPrice: string): string => {
    const { numericValue, symbol, decimalSeparator } =
      parseCurrency(monthlyPrice);
    const price = numericValue * 12;
    return formatCurrency(price, symbol, decimalSeparator);
  };

  return (
    <>
      <CompareCardContainer data-testid="yearly-plan-compare-card-container">
        <PlanCard
          planName={currentPlan?.plan?.frequency}
          pagesTotal={currentPlan?.plan?.pages}
          pricesPage={currentPlan?.plan?.price}
          circlesNumber={currentPlan?.index + 1}
        />
        <PlanCard
          planName={currentPlan?.plan?.frequency}
          pagesTotal={correspondingYearlyPlan?.pages}
          pricesPage={correspondingYearlyPlan?.price}
          circlesNumber={currentPlan?.index + 1}
          isYearly={true}
          yearlyPrice={calculateYearlyPriceWithoutSavings(
            currentPlan?.plan?.price
          )}
        />
      </CompareCardContainer>
    </>
  );
};
