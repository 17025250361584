import { ConsumerPlansAction } from '../types/consumerPlansAction';

export const setAssetsProviderAction = (
  language: string,
  country: string
): ConsumerPlansAction => ({
  type: 'SET_ASSETS_PROVIDER',
  language,
  country
});
