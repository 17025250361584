import React from 'react';
import { ContentContainer, InfoContainer } from './styles';
import { TitleAndSubtitle } from '../common/components/TitleAndSubtitle';
import { TrialInfo } from '../common/components/TrialInfo';
import { PlanDetails } from '../common/components/PlanDetails';
import { PlanImage } from '../common/components/PlanImage';
import { PlanInfoButtons } from '../common/components/PlanInfoButtons';

interface MobilePlanInfoProps {
  setShowIndividualPlanInfo: (state: boolean) => void;
}

export const MobilePlanInfo: React.FC<MobilePlanInfoProps> = ({
  setShowIndividualPlanInfo
}) => {
  return (
    <div data-testid="plans-mobile-plan-info">
      <ContentContainer>
        <PlanImage />
        <InfoContainer>
          <TitleAndSubtitle />
          <TrialInfo />
          <PlanInfoButtons
            setShowIndividualPlanInfo={setShowIndividualPlanInfo}
          />
          <PlanDetails />
        </InfoContainer>
      </ContentContainer>
    </div>
  );
};
