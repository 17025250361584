import { PlanSelectorActionType } from '../../common/types/planSelectorAction';
import { DashboardPlan } from '../../common/types/dashboardPlan';

export const setSelectedPlanAction = (selectedPlan: {
  id: number;
  index: number;
  guid: string;
  plan: DashboardPlan;
  isLoading?: boolean;
}) => ({
  type: PlanSelectorActionType.SET_SELECTED_PLAN,
  selectedPlan
});
