import styled from 'styled-components';

export type SkeletonProps = {
  isMobile: boolean;
};

export const SkeletonElement = styled.div<SkeletonProps>`
  width: 100%;
  display: ${({ isMobile }) => (isMobile ? 'block' : 'flex')};
  align-items: center;
  justify-content: center;
  margin: 24px 0px;

  .skeleton-ui {
    width: 100%;
    height: 100%;
  }

  .mobile-loader {
    height: 50px;
  }
`;
export const SkeletonLoading = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  background: linear-gradient(110deg, #cfcfcf 8%, #f7f6f6 18%, #cfcfcf 33%);
  border-radius: 10px;
  background-size: 200% 100%;
  animation: 1.5s skeleton linear infinite;

  @keyframes skeleton {
    to {
      background-position-x: -200%;
    }
  }
`;
