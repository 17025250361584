import React from 'react';
import { ShellProps } from '../src/common/types/shell';
import projectNames from '../src/common/config/projectNames';
import { ThemeProvider } from '@veneer/theme';
import {
  ConsumerPlanSelector,
  LandingPagePlans,
  PlanSelector,
  PlanSelectorV3
} from '../src';
declare global {
  interface Window {
    Shell: ShellProps;
  }
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({ ...props }) {
  const Component = () => {
    const properties = props.properties;

    if (props.properties.componentName === 'PlanSelectorV3') {
      return <PlanSelectorV3 {...properties} />;
    }

    if (props.properties.componentName === 'ConsumerPlanSelector') {
      return <ConsumerPlanSelector {...properties} />;
    }

    if (props.properties.componentName === 'LandingPagePlans') {
      return <LandingPagePlans {...properties} />;
    }

    if (props.properties.componentName === 'PlanSelector') {
      return <PlanSelector {...properties} />;
    }

    return <h1>ComponentName not found</h1>;
  };

  return (
    <ThemeProvider
      customSemantics={props.properties.themeSettings.customSemantics}
      mode={props.properties.themeSettings.mode}
      shape={props.properties.themeSettings.shape}
      density={props.properties.themeSettings.density}
    >
      <section
        className={`${projectNames.namespace}`}
        id={projectNames.packageJsonName}
      >
        {Component()}
      </section>
    </ThemeProvider>
  );
}
