import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { getColor } from '../../../../../../../../common/utils/colorSelector';

export const HighlightedInfoContainer = styled.div`
  && {
    /* margin-right: 60px; */
  }
`;
interface TrialInfoProps {
  program: string;
}

const getTrialBackgroundColor = (program) => {
  let color;

  switch (program) {
    case 'i_ink_paper':
      color = '#f6f5ff';
      break;
    case 'i_ink':
      color = '#f8eefe';
      break;
    case 'i_toner':
      color = '#edeefe';
      break;
    default:
      color = '#f6f5ff';
  }

  return color;
};

export const TrialInfoContainer = styled.div<TrialInfoProps>`
  && {
    display: flex;
    background-color: ${({ program }) => getTrialBackgroundColor(program)};
    margin-top: 24px;
    border-radius: 12px;
    align-items: flex-start;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
  }
`;

export const TrialInfoContent = styled.span`
  &&& {
    font-size: 14px;
    font-weight: 400;
    color: ${primitives.color.gray12};
    line-height: 20px;
    font-family: ${primitives.typography.family0};
    margin-left: 12px;

    b {
      font-weight: 700;
      font-family: ${primitives.typography.family0};
    }
  }
`;

interface IconContainerProps {
  program: string;
}

export const IconContainer = styled.div<IconContainerProps>`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    min-width: 20px;
    background-color: ${({ program }) => getColor(program)};
    border-radius: 10px;
    margin-left: 12px;
  }
`;
