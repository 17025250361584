import { JarvisPlansAction } from '../types/jarvisPlansAction';

export const setAssetsProviderAction = (
  language: string,
  country: string
): JarvisPlansAction => ({
  type: 'SET_ASSETS_PROVIDER',
  language,
  country
});
