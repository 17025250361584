import React from 'react';
import { PlanName } from '../PlanName';
import { PlanTag } from '../PlanTag';
import { PagesPriceInfo } from '../PagesPriceInfo';
import IconCheckmark from '@veneer/core/dist/scripts/icons/icon_checkmark';
import {
  PlanCardContainer,
  TransparentTag,
  InfoContainer,
  HorizontalLine,
  Container,
  InfoTextContainer,
  ColorContainer
} from './styles';
import { DashboardPlan } from '../../../common/types/dashboardPlan';
import { useDashboardPlansState } from '../../hooks/useDashboardPlansState';
import { useDispatch } from '../../hooks/useDispatch';
import { setSelectedPlanAction } from '../../actions/setSelectedPlanAction';
import {
  ANALYTICS_EVENT_LABEL,
  INITIATED_UNSUBSCRIBE,
  PLAN_PROGRAMMES,
  SUBSCRIBED_NO_PENS
} from '../../../common/constants';
import { useGetText } from '../../hooks/useGetText';

interface PlanCardYearlyProps {
  index: number;
  plan: DashboardPlan;
}
export const PlanCardYearly: React.FC<PlanCardYearlyProps> = ({
  index,
  plan
}) => {
  const {
    selectedPlan,
    program,
    onPlanChange,
    subscriptionState,
    pausedPlanGUID,
    defaultPlanGUIDInk,
    defaultPlanGUIDToner,
    trackClickEvent
  } = useDashboardPlansState();
  const getText = useGetText();
  const isCurrentPlan = plan.guid === selectedPlan.guid;
  const isPausedPlan = plan.guid === pausedPlanGUID;
  const dispatch = useDispatch();
  const isInitiatedUnsubscribe = subscriptionState === INITIATED_UNSUBSCRIBE;
  const isConsumerInk =
    program === PLAN_PROGRAMMES.INSTANT_INK ||
    program === PLAN_PROGRAMMES.INSTANT_INK_PAPER;
  const defaultPlanGUID = isConsumerInk
    ? defaultPlanGUIDInk
    : defaultPlanGUIDToner;

  return (
    <PlanCardContainer
      data-testid={'plans-selector-plan-card-container'}
      data-linkid="ii_plans"
      data-analytics-id="ii_plans"
      isSelected={isCurrentPlan || isPausedPlan}
      isYearly
      disableClick={(isCurrentPlan && !isPausedPlan) || isInitiatedUnsubscribe}
      onClick={() => {
        trackClickEvent(
          ANALYTICS_EVENT_LABEL,
          'plans-selector-plan-card-container'
        );
        if (subscriptionState === SUBSCRIBED_NO_PENS) {
          dispatch(
            setSelectedPlanAction({
              id: plan.id,
              index: index,
              guid: plan.guid,
              plan: plan,
              isLoading: true
            })
          );
        }

        onPlanChange(plan);
      }}
    >
      {plan.guid === defaultPlanGUID || isCurrentPlan || isPausedPlan ? (
        <PlanTag
          dataTestId={'plans-selector-plan-card-tag'}
          tagTitle={'current_plan'}
          isSelected
        />
      ) : (
        <TransparentTag />
      )}
      <InfoContainer>
        <PlanName
          dataTestId={'plans-selector-plan-card-name'}
          planName={plan.frequency}
          circlesNumber={index + 1}
          isYearly
        />
        <PagesPriceInfo
          dataTestId={'plans-selector-plan-card-price-info'}
          pages={plan.pages}
          price={plan.price}
          isYearly
        />

        <HorizontalLine />
      </InfoContainer>
      <Container>
        <InfoTextContainer data-testid={`plans-selector-plan-card-info1`}>
          <IconCheckmark
            size={16}
            color="gray12"
            customStyle={{ flex: 'none' }}
          />
          <span>
            {getText(
              'plans_selector.yearly.plan_card.pages_throughout_the_year'
            )}
          </span>
        </InfoTextContainer>
        <InfoTextContainer data-testid={`plans-selector-plan-card-info2`}>
          <IconCheckmark
            size={16}
            color="gray12"
            customStyle={{ flex: 'none' }}
          />
          <span>
            {getText('plans_selector.yearly.plan_card.one_annual_payment')}
          </span>
        </InfoTextContainer>
      </Container>
      <div style={{ paddingTop: 24, pointerEvents: 'none' }}>
        <ColorContainer program={'yearly_plan'} />
      </div>
    </PlanCardContainer>
  );
};
