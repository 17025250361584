import React from 'react';
import {
  LargeFont,
  PriceStrike,
  YearlyPlanCard,
  YearlyPlanContainer,
  CardContent,
  SaveText,
  PlaceHolder,
  SmallFont,
  CardText,
  SelectPlanButton,
  ButtonArea,
  SelectPlanButtonText
} from './styles';
import { PlanTag } from '../../../YearlyPlanSelector/components/PlanTag';
import { PlanName } from '../PlanName';
import { useGetText } from '../../../common/hooks/useGetText';
import { useDispatch } from '../../hooks/useDispatch';
import { setCurrentStepAction } from '../../actions/setCurrentStepAction';
import { ARN_STEP } from '../../../common/constants';
import { setSelectedYearlyPlanAction } from '../../actions/setSelectedYearlyPlanAction';
import { DashboardPlan } from '../../../common/types/dashboardPlan';

interface YearlyPlansCardProps {
  name: string;
  pricePerYear: string;
  numberOfCircles?: number;
  tagColor?: string;
  hasTag?: boolean;
  dataTestId?: string;
  savingsPercentage?: string;
  yearlyPlan: DashboardPlan;
}

export const YearlyPlansCard: React.FC<YearlyPlansCardProps> = ({
  name,
  pricePerYear,
  numberOfCircles,
  dataTestId,
  tagColor = '#E6E6E6',
  hasTag = false,
  savingsPercentage,
  yearlyPlan
}) => {
  const getText = useGetText();
  const dispatch = useDispatch();

  const handleSelectPlanClick = () => {
    if (dispatch) {
      dispatch(setCurrentStepAction(ARN_STEP));
      dispatch(setSelectedYearlyPlanAction(yearlyPlan));
    }
  };

  return (
    <div data-testid="yearly-plans-yearly-plans-card">
      <YearlyPlanCard data-testid={dataTestId}>
        {hasTag ? (
          <PlanTag
            tagTitle={getText('plans_selector.common.your_yearly_plan')}
            color={tagColor}
          />
        ) : (
          <PlaceHolder />
        )}
        <YearlyPlanContainer>
          <PlanName
            planName={name}
            circlesNumber={numberOfCircles}
            blueColor={true}
          />
          <CardContent>
            <CardText>
              <p>
                <LargeFont>{yearlyPlan.pages.toString()}</LargeFont>{' '}
                {getText('plans_selector.plan_card.pages_per_year')}
              </p>
              <p>
                <PriceStrike>{pricePerYear}</PriceStrike>
                <SmallFont>
                  {' '}
                  {getText('plans_selector.plan_card.per_year')}
                </SmallFont>
              </p>
              <p>{yearlyPlan.price}</p>
              <SaveText>
                {getText(
                  'plans_selector.yearly.modals.all_yearly_plans.save_per_year',
                  { amount: savingsPercentage }
                )}
              </SaveText>
            </CardText>
            <ButtonArea>
              <SelectPlanButton
                data-testid="yearly-plans-card-select-plan-button"
                onClick={handleSelectPlanClick}
                appearance="secondary"
              >
                <SelectPlanButtonText>
                  {getText('plans_selector.plan_modal.select_plan')}
                </SelectPlanButtonText>
              </SelectPlanButton>
            </ButtonArea>
          </CardContent>
        </YearlyPlanContainer>
      </YearlyPlanCard>
    </div>
  );
};
