import React, { useEffect, useState } from 'react';
import {
  useGetText,
  usePlanSelectorState,
  useWindowSize
} from '../../../hooks';
import { SubtitleContainer, TitleContainer } from './styles';
import { TABLET_LANDSCAPE_MAX } from '../../../../../common/constants';
import { isSMBPlanProgram } from '../../../../../common/lib/smbHelper';
import IconPrinterWarning from '@veneer/core/dist/scripts/icons/icon_printer_warning';

export const PlanTitle: React.FC = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const getText = useGetText();
  const { program } = usePlanSelectorState();
  const isSMBProgram = isSMBPlanProgram(program);

  const resolution = useWindowSize();

  useEffect(() => {
    setIsDesktop(resolution >= TABLET_LANDSCAPE_MAX);
  }, [resolution]);

  return (
    <TitleContainer isDesktop={isDesktop} isSMBProgram={isSMBProgram}>
      <h4 data-testid="plan-card-title">
        {getText('plans_selector.plan_modal.select_printing_plan')}
      </h4>
      <React.Fragment>
        <SubtitleContainer>
          <p data-testid="plan-card-subtitle-smb">
            {getText('plans_selector.plan_modal.subtitle')}
          </p>
        </SubtitleContainer>

        {isSMBProgram && (
          <div className="smb-blue-banner">
            <IconPrinterWarning className="icon-printer" color="hpBlue6" />
            <p className="high-usage-label">
              {getText('plans_selector.plan_modal.high_usage_label')}
            </p>
          </div>
        )}
      </React.Fragment>
    </TitleContainer>
  );
};
