import React from 'react';
import { AttributesContainer, ItemsContainer } from './styles';
import { PlanTitle } from './components/PlanTitle';
import { PlanAttributes } from './components/PlanAttributes';
import { PlanSelection } from './components/PlanSelection';
import { CardContentContainer } from '../CardContentContainer';
import { Plan } from 'src/EnrollmentPlans/V3/types/planSelectorV3State';

interface PlanInfoProps {
  plan: Plan;
  planInfo: {
    img: string;
    imgTitle: string;
    title: string;
    description: string;
    learnMore: boolean;
  };
  attributes: string[];
  isLastChild?: boolean;
}

export const PlanInfo: React.FC<PlanInfoProps> = ({
  planInfo,
  attributes,
  plan,
  isLastChild
}) => {
  return (
    <CardContentContainer
      dataTestId="plan-info"
      leftContent={<PlanTitle planInfo={planInfo} isLastChild={isLastChild} />}
      applyBottomRightBorder={isLastChild}
      rightContent={
        <AttributesContainer
          isLastChild={isLastChild}
          data-testid="plan-attributes-container"
        >
          <ItemsContainer isLastChild={isLastChild}>
            <PlanAttributes attributes={attributes} />
            <PlanSelection plan={plan} isLastChild={isLastChild} />
          </ItemsContainer>
        </AttributesContainer>
      }
    />
  );
};
