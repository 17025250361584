import React from 'react';

import {
  ImageContainer,
  InkPaperImage,
  InkImage,
  TonerImage,
  Spacer
} from './styles';
import { useConsumerPlansState } from '../../../../../../hooks/useConsumerPlansState';
import { useWindowSize } from '../../../../../../../../common/hooks/useWindowSize';
import {
  MIN_DESKTOP_RESOLUTION,
  MIN_TABLET_RESOLUTION
} from '../../helpers/resolutionHelper';
import inkPaperImg from '../../../../../../../../../imgs/ink-paper.png';
import inkOnlyImg from '../../../../../../../../../imgs/ink-only.png';
import tonerImg from '../../../../../../../../../imgs/toner-image.svg';
import tonerTabletImg from '../../../../../../../../../imgs/toner-image-tablet.svg';

export const PlanImage: React.FC = () => {
  const { program, selectedPlan } = useConsumerPlansState();
  const isInkPaper = selectedPlan?.isInkPaperPlan;
  const resolution = useWindowSize();
  const isDesktop = resolution >= MIN_DESKTOP_RESOLUTION;
  const isTablet =
    resolution < MIN_DESKTOP_RESOLUTION && resolution >= MIN_TABLET_RESOLUTION;
  const isMobile = resolution < MIN_TABLET_RESOLUTION;

  const responsivePlanImage = () => {
    if (isDesktop) {
      if (isInkPaper) {
        return (
          <InkPaperImage
            isDesktop
            src={inkPaperImg}
            width="340"
            height="340"
            alt="Photo of Hp paper and ink cartridges"
            data-testid="desktop-ink-paper-image"
          />
        );
      } else if (program === 'i_ink') {
        return (
          <InkImage
            isDesktop
            src={inkOnlyImg}
            width="340"
            height="340"
            alt="Photo of Hp ink cartridges"
            data-testid="desktop-ink-image"
          />
        );
      } else {
        return (
          <TonerImage
            isDesktop
            src={tonerImg}
            width="340"
            height="140"
            alt="Photo of Hp toner cartridges"
            data-testid="desktop-toner-image"
          />
        );
      }
    } else if (isTablet) {
      if (isInkPaper) {
        return (
          <InkPaperImage
            isTablet
            src={inkPaperImg}
            width="200"
            height="200"
            alt="Photo of Hp paper and ink cartridges"
            data-testid="tablet-ink-paper-image"
          />
        );
      } else if (program === 'i_ink') {
        return (
          <InkImage
            isTablet
            src={inkOnlyImg}
            width="180"
            height="180"
            alt="Photo of Hp ink cartridges"
            data-testid="tablet-ink-image"
          />
        );
      } else {
        return (
          <>
            <Spacer />
            <TonerImage
              isTablet
              src={tonerTabletImg}
              width="236"
              height="200"
              alt="Photo of Hp toner cartridges"
              data-testid="tablet-toner-image"
            />
          </>
        );
      }
    } else {
      if (isInkPaper) {
        return (
          <InkPaperImage
            isMobile
            src={inkPaperImg}
            width="80"
            height="80"
            alt="Photo of Hp paper and ink cartridges"
            data-testid="mobile-ink-paper-image"
          />
        );
      } else if (program === 'i_ink') {
        return (
          <InkImage
            isMobile
            src={inkOnlyImg}
            width="80"
            height="80"
            alt="Photo of Hp ink cartridges"
            data-testid="mobile-ink-image"
          />
        );
      } else {
        return (
          <TonerImage
            isMobile
            src={tonerImg}
            width="164"
            height="80"
            alt="Photo of Hp toner cartridges"
            data-testid="mobile-toner-image"
          />
        );
      }
    }
  };
  return (
    <ImageContainer
      isDesktop={isDesktop}
      isTablet={isTablet}
      isMobile={isMobile}
    >
      {responsivePlanImage()}
    </ImageContainer>
  );
};
