import React from 'react';

import {
  PaperCardContainer,
  ButtonContainer,
  ColorContainer,
  TitleContainer,
  PriceContainer,
  Description,
  ImageContainer,
  PaperImage
} from './styles';
import { useDashboardPlansState } from '../../hooks/useDashboardPlansState';
import Button from '@veneer/core/dist/scripts/button';
import { useGetText } from '../../hooks/useGetText';

export const PaperCard: React.FC = () => {
  const { program, handlePaperCardClick, paperStartingPrice } =
    useDashboardPlansState();
  const getText = useGetText();

  return (
    <PaperCardContainer
      data-testid="dashboard-plan-selector-paper-card-container"
      data-linkid="ii_plans"
      data-analytics-id="ii_plans"
      onClick={() => (handlePaperCardClick ? handlePaperCardClick() : null)}
    >
      <ImageContainer>
        <PaperImage />
      </ImageContainer>
      <TitleContainer data-testid="dashboard-plan-selector-paper-card-title">
        <h1>
          {getText('plans_selector.paper_card.add_paper_to_your_ink_plan')}
        </h1>
      </TitleContainer>
      <PriceContainer data-testid="dashboard-plan-selector-paper-card-price">
        <h2>{`${getText('plans_selector.paper_card.starting_at')}
          ${paperStartingPrice}
          ${getText('plans_selector.plan_card.price_per_month')}`}</h2>
      </PriceContainer>
      <Description data-testid="dashboard-plan-selector-paper-card-description">
        {getText('plans_selector.paper_card.instant_ink_now_offers')}
      </Description>
      <ButtonContainer>
        <Button
          data-testid="dashboard-plan-selector-paper-card-button"
          data-linkid="ii_plans"
          data-analytics-id="ii_plans"
        >
          {getText('plans_selector.learn_more')}
        </Button>
      </ButtonContainer>
      <div style={{ paddingTop: 24 }}>
        <ColorContainer
          program={program == 'i_ink_paper' ? 'i_ink' : program}
        />
      </div>
    </PaperCardContainer>
  );
};
