import { Plan } from '../../EnrollmentPlans/V2/types/consumerPlansState';
import { setSelectedPlanAction } from '../../EnrollmentPlans/V2/actions/setSelectedPlanAction';
import { Dispatch } from 'react';
import { ConsumerPlansAction } from '../../EnrollmentPlans/V2/types/consumerPlansAction';

type TrackClickEvent = (eventLabel: string, eventDetail: string) => void;

export const updatePlanSelection = (
  plan: Plan,
  index: number,
  isInkPaper: boolean,
  trackClickEvent: TrackClickEvent,
  ANALYTICS_EVENT_LABEL: string,
  dispatch: Dispatch<ConsumerPlansAction>
) => {
  if (trackClickEvent) {
    trackClickEvent(
      ANALYTICS_EVENT_LABEL,
      `${plan.program.replace('i_', '')}-plan-${plan.frequency}`
    );
  }
  if (dispatch) {
    dispatch(
      setSelectedPlanAction({
        id: plan.id,
        index,
        guid: isInkPaper ? `${plan.guid}_i_ink_paper` : plan.guid,
        plan,
        isInkPaperPlan: isInkPaper
      })
    );
  }
};

export const isSelectedPlan = (
  plan: Plan,
  selectedPlanGuid: string,
  isInkPaper: boolean
) => {
  if (isInkPaper) {
    return `${plan.guid}_i_ink_paper` === selectedPlanGuid;
  }
  return plan.guid === selectedPlanGuid;
};
