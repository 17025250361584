import styled from 'styled-components';
import primitives from '@veneer/primitives';
import Accordion from '@veneer/core/dist/scripts/accordion';
import { breakpointsHelper } from '../../../common/utils/breakpointsHelper';

export const DisclaimerTitle = styled.h2`
  && {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: ${primitives.color.gray7};
  }
`;

export const DisclaimerContent = styled.div`
  margin: 16px;
`;

export const DisclaimerItem = styled.p`
  && {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-indent: -12px;
    color: #737373;
  }
`;

export const VeneerAccordion = styled(Accordion)`
  && {
    margin-top: 32px;

    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      margin-top: 24px;
    }
  }
`;
