import React from 'react';
import { useGetText } from '../../hooks';
import { PLAN_PROGRAMMES } from '../../../../common/constants';

const PLAN_PROGRAM_BADGES_DESKTOP = (translations) => ({
  SMB_RECOMMENDED: (
    <div className="smb-banner" data-testid="recommended-plan-desktop">
      {translations.recommended}
    </div>
  ),
  II_RECOMMENDED: (
    <div className="smb-banner" data-testid="recommended-plan-desktop">
      {translations.recommended}
    </div>
  ),
  II_POPULAR: (
    <div className="smb-banner" data-testid="popular-plan-desktop">
      {translations.mostPopular}
    </div>
  )
});

const PLAN_PROGRAM_BADGES_MOBILE = (translations) => ({
  SMB_RECOMMENDED: (
    <div className="plan-name">
      <p className="banner" data-testid="recommended-plan-mobile">
        {translations.recommended}
      </p>
    </div>
  ),
  II_RECOMMENDED: (
    <div className="plan-name">
      <p className="banner" data-testid="recommended-plan-mobile">
        {translations.recommended}
      </p>
    </div>
  ),
  II_POPULAR: (
    <div className="plan-name">
      <p className="banner" data-testid="popular-plan-mobile">
        {translations.mostPopular}
      </p>
    </div>
  )
});

const getBadge = ({
  isDesktop,
  isRecommended,
  isPopular,
  program,
  translations
}) => {
  const getBadge = isDesktop
    ? PLAN_PROGRAM_BADGES_DESKTOP
    : PLAN_PROGRAM_BADGES_MOBILE;

  switch (program) {
    case PLAN_PROGRAMMES.SMB_MONO:
    case PLAN_PROGRAMMES.SMB:
      return isRecommended && getBadge(translations).SMB_RECOMMENDED;
    case PLAN_PROGRAMMES.INSTANT_INK:
      return isPopular && getBadge(translations).II_POPULAR;
    case PLAN_PROGRAMMES.INSTANT_TONER:
      return isRecommended && getBadge(translations).II_RECOMMENDED;
    default:
      return null;
  }
};

export const PlanBadge = (props) => {
  const getText = useGetText();
  const translations = {
    recommended: getText('plans_selector.plan_card.recommended'),
    mostPopular: getText('plans_selector.most_popular')
  };
  return getBadge({ ...props, translations });
};
