import React from 'react';
import { GenericSlider } from './SliderRebranded';
import PlanCard from '../PlanCard';
import { usePlanSelectorState, useWindowSize } from '../../hooks';
import { TABLET_LANDSCAPE_MAX } from '../../../../common/constants';
import SkeletonContainer from '../SkeletonContainer';

export const PlansSlider = ({ filteredPlans }) => {
  const { program, plansLoading, country, trackClickEvent } =
    usePlanSelectorState();

  const resolution = useWindowSize();
  const isDesktop = resolution >= TABLET_LANDSCAPE_MAX;

  const getPlanCards = React.useMemo(
    () =>
      filteredPlans.map((plan, index) => {
        return (
          <PlanCard
            key={`${plan.guid}-${index}`}
            plan={plan}
            country={country}
            trackClickEvent={trackClickEvent}
          />
        );
      }),
    [filteredPlans]
  );

  if (plansLoading)
    return <SkeletonContainer program={program} isMobile={!isDesktop} />;

  return <GenericSlider>{getPlanCards}</GenericSlider>;
};
