import styled from 'styled-components';
import primitives from '@veneer/primitives';

import CVVIconPng from '../../../../../../imgs/cvv-icon.png';

export const mobileDesignBreakpoint = `665px`;

export const Container = styled.div`
  && {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    border-radius: 8px;
    border: 1px solid ${primitives.color.vividSkyBlue6};
    padding: 20px 16px;

    color: ${primitives.color.gray12};
  }
`;

export const InnerContainer = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;

    > * {
      margin-top: 16px;
    }

    @media (min-width: ${mobileDesignBreakpoint}) {
      flex-direction: row;
    }
  }
`;

export const CardInfoContainer = styled.div`
  && {
    flex: 1;
    min-width: 180px;
    display: flex;

    @media (min-width: ${mobileDesignBreakpoint}) {
      margin-right: 12px;
    }
  }
`;

export const CardFlagContainer = styled.div`
  && {
    width: 40px;
    max-height: 24px;
    margin-right: 12px;

    > img {
      width: 100%;
    }
  }
`;

export const InnerCardInfo = styled.div``;

export const P = styled.p`
  && {
    font-family: ${primitives.typography.family0};
    font-size: ${primitives.typography.size0};
    line-height: ${primitives.typography.lineHeight1};
    font-weight: ${primitives.typography.weight3};
  }
`;

export const Link = styled.p`
  && {
    font-size: ${primitives.typography.size0};
    line-height: ${primitives.typography.lineHeight1};
    color: #0278ab;
    cursor: pointer;
  }
`;

export const CVVEntryContainer = styled.div`
  && {
    flex: 2;
  }
`;

export const CVVEntryInnerContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;

    margin-top: 12px;
    margin-bottom: 12px;

    @media (min-width: ${mobileDesignBreakpoint}) {
      flex-direction: row;
      margin: 0;
    }
  }
`;

export const CVVInputContainer = styled.div`
  width: 100%;

  @media (min-width: ${mobileDesignBreakpoint}) {
    margin-right: 8px;
  }
`;

export const CVVIcon = styled.div`
  && {
    width: 32px;
    height: 32px;
    background-image: url(${CVVIconPng});
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const Notification = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 8px;

  > *:first-child {
    margin-right: 6px;
    min-width: 24px;
  }

  @media (min-width: ${mobileDesignBreakpoint}) {
    align-items: center;
  }
`;

export const NotificationMessage = styled.p`
  && {
    font-size: ${primitives.typography.size1};
    line-height: ${primitives.typography.lineHeight2};
    font-weight: ${primitives.typography.weight3};
  }
`;
