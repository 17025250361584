import {
  COLORS_LIMIT_II,
  COLORS_LIMIT_SMB,
  HIGHEST_COLOR_INDEX,
  COLOR_STARTING_INDEX_SMB,
  COLOR_STARTING_INDEX_II,
  PLAN_PROGRAMMES,
  DEFAULT_COLOR_PINK,
  DEFAULT_COLOR_GRAY,
  DEFAULT_COLOR_BLUE_UCDE,
  DEFAULT_COLOR_BLUE_SMB
} from '../constants';
import { colorTokens } from './oldVeneerColorTokens';
import { isSMBPlanProgram } from './smbHelper';

export const getColorByProgramAndOrder = (program: string, order = 0) => {
  const colorMap = {
    [PLAN_PROGRAMMES.INSTANT_INK]: DEFAULT_COLOR_PINK,
    [PLAN_PROGRAMMES.INSTANT_TONER]: DEFAULT_COLOR_BLUE_UCDE,
    [PLAN_PROGRAMMES.SMB]: DEFAULT_COLOR_BLUE_SMB,
    [PLAN_PROGRAMMES.SMB_MONO]: DEFAULT_COLOR_GRAY
  };
  const color = colorMap[program] || DEFAULT_COLOR_BLUE_SMB;
  const isSmb = isSMBPlanProgram(program);
  const colorsLimit = isSmb ? COLORS_LIMIT_SMB : COLORS_LIMIT_II;
  const startingColorIndex =
    isSmb || program === PLAN_PROGRAMMES.INSTANT_INK
      ? COLOR_STARTING_INDEX_SMB
      : COLOR_STARTING_INDEX_II;
  return colorTokens[
    color +
      (order > colorsLimit ? HIGHEST_COLOR_INDEX : order + startingColorIndex)
  ];
};
