import React from 'react';
import {
  getDesktopPlanCardByProgram,
  getMobilePlanCardByProgram
} from '../PlanCard';
import { SkeletonElement, SkeletonLoading } from './styles';

interface ISkeletonContainer {
  isMobile?: boolean;
  program: string;
}

const SkeletonContainer: React.FC<ISkeletonContainer> = ({
  isMobile,
  program
}) => {
  const PlanCard = isMobile
    ? getMobilePlanCardByProgram(program)
    : getDesktopPlanCardByProgram(program);
  const loaderClass = isMobile ? 'mobile-loader' : 'desktop-loader';

  return (
    <SkeletonElement isMobile={isMobile}>
      {[...Array(5)].map((item, index) => (
        <PlanCard
          key={`loader-${item}-${index}`}
          appearance="dropShadow"
          content={<SkeletonLoading className={`skeleton-ui ${loaderClass}`} />}
        />
      ))}
    </SkeletonElement>
  );
};

export default SkeletonContainer;
