import React from 'react';
import { useGetText } from '../../hooks/useGetText';

import { Container, PagesContainer, PriceContainer } from './styles';

interface PagesPriceInfoProps {
  pages: number;
  price: string;
  zeroPlanPrice?: string;
  dataTestId?: string;
  isZeroPlan?: boolean;
  isYearly?: boolean;
}

export const PagesPriceInfo: React.FC<PagesPriceInfoProps> = ({
  pages,
  price,
  dataTestId,
  isZeroPlan,
  zeroPlanPrice,
  isYearly = false
}) => {
  const getText = useGetText();
  const period = isYearly ? 'year' : 'month';
  const pageSet = isZeroPlan
    ? 'plans_selector.pay_as_you_print.pages_set'
    : `plans_selector.plan_card.pages_per_${period}`;
  const setPrice = isZeroPlan
    ? 'plans_selector.pay_as_you_print.set'
    : `plans_selector.plan_card.per_${period}`;
  const planPrice = isZeroPlan ? zeroPlanPrice : price;

  return (
    <Container>
      <PagesContainer data-testid={`${dataTestId}-pages-container`}>
        <h1 data-testid={`${dataTestId}-pages-title`}>
          {isZeroPlan ? 15 : pages}
        </h1>
        <h2>{getText(pageSet)}</h2>
      </PagesContainer>
      <PriceContainer data-testid={`${dataTestId}-price-container`}>
        <h1 data-testid={`${dataTestId}-price-title`}>{planPrice}</h1>
        <h2>{getText(setPrice)}</h2>
      </PriceContainer>
    </Container>
  );
};
