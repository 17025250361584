import styled, { css } from 'styled-components';
import primitives from '@veneer/primitives';
import VeneerButton from '@veneer/core/dist/scripts/button';

export const Container = styled.div`
  && {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
`;

interface TitleProps {
  isMobile?: boolean;
}

export const Title = styled.h1<TitleProps>`
  && {
    font-family: ${primitives.typography.family0};
    color: ${primitives.color.gray12};
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 16px 0;
    white-space: ${({ isMobile }) => (isMobile ? 'normal' : 'no-wrap')};
  }
`;

export const InfoContainer = styled.div`
  && {
    display: flex;
    align-items: center;
    padding-right: 20px;

    span {
      font-family: ${primitives.typography.family0};
      color: ${primitives.color.gray9};
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-left: 8px;
    }
  }
`;

interface ModalInfoContainerProps {
  disabledIcon: boolean;
}

export const ModalInfoContainer = styled.div<ModalInfoContainerProps>`
  && {
    display: inline-block;
    align-items: center;
    max-width: 570px;

    @media (max-width: 970px) {
      max-width: 100%;
    }

    div {
      font-family: ${primitives.typography.family0};
    }

    span {
      font-family: ${primitives.typography.family0};
      color: ${primitives.color.gray9};
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      margin-right: 4px;
    }

    svg {
      cursor: ${(props) => (props.disabledIcon ? 'default' : 'pointer')};
      vertical-align: middle;
    }
  }
`;

export const BottomContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }
`;

interface IconContainerProps {
  size: number;
}

export const IconContainer = styled.div<IconContainerProps>`
  && {
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
  }
`;

export const UpdatePlanAndShippingContainer = styled.div`
  && {
    display: flex;
    flex-direction: row;

    @media (max-width: 970px) {
      flex-direction: column;
    }
  }
`;

export const ModalInfoAndButtonContainer = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;

    @media (max-width: 970px) {
      flex-direction: column;
    }
  }
`;

interface ButtonProps {
  $isTablet?: boolean;
  $isMobile?: boolean;
}

export const Button = styled(VeneerButton)<ButtonProps>`
  height: fit-content;
  && {
    ${({ $isTablet, $isMobile }) => {
      if ($isTablet) {
        return css`
          align-self: flex-end;
          margin-top: 40px;
        `;
      }
      if ($isMobile) {
        return css`
          align-self: center;
          width: 100%;
          margin-top: 40px;
          margin-bottom: 40px;
        `;
      }
    }}
  }
`;

export const IconInfoHPX = styled.img<{ disableClick: boolean }>`
  && {
    width: 17px;
    height: 17px;
    cursor: ${(props) => (props.disableClick ? 'none' : 'pointer')};
    pointer-events: ${(props) => (props.disableClick ? 'none' : 'auto')};
  }
`;
