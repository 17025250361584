import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { Resposive_Container } from '../../../../../common/types/responsiveContainer';

export const TitleContainer = styled.div<Resposive_Container>`
  && {
    display: block;
    font-weight: normal;
    text-align: center;
    line-height: 32px;
    margin-bottom: 16px;

    h4 {
      font-family: ${primitives.typography.family0};
      font-size: ${(props) => (props.isDesktop ? '32px' : '28px')};
      margin: 8px 0px 0px 0px;
      line-height: 40px;
      color: ${primitives.color.gray10};
      font-weight: 400;
    }
    p {
      text-align: center;
      font-family: ${primitives.typography.family0};
    }
    .icon-printer {
      display: inline;
      position: relative;
      margin: 0 10px 25px 10px;
    }
    .high-usage-label {
      position: absolute;
      margin-left: 40px;
      display: inline;
      font-weight: 500;
      font-style: normal;
      font-size: 12px;
      line-height: 20px;
      padding-right: 16px;
      margin-bottom: 20px;
      max-width: ${(props) => (props.isDesktop ? `334px` : `65%`)};
    }
    .smb-blue-banner {
      z-index: -1;
      margin: 25px auto 0;
      max-width: 370px;
      height: ${(props) => (props.isDesktop ? `92px` : `112px`)};
      background: #e6f2f7;
      border-radius: 16px 16px 0px 0px;
      text-align: left;
      padding: 0 16px;
      display: flex;
      align-items: center;
      margin-bottom: -72px;
      @media (max-width: 800px) {
        margin-bottom: -50px;
      }
    }
  }
`;

export const SubtitleContainer = styled.div`
  && {
    display: flex;
    justify-content: center;

    p {
      font-size: 16px;
      color: ${primitives.color.gray10};
      background: transparent;
      vertical-align: baseline;
      line-height: 24px;
      margin-top: 10px;
    }
  }
`;
