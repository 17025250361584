import React from 'react';
import { useGetText } from '../../../common/hooks/useGetText';
import { SwitchMonthlyToYearlySection } from '../SwitchMonthlyToYearlySection';
import { YearlyPlanComparisonTable } from '../YearlyPlanComparisonTable';
import { CompareYearlyPlanDisclaimer } from '../CompareYearlyPlanDisclaimer';
import { useDispatch } from '../../hooks/useDispatch';
import { setCurrentStepAction } from '../../actions/setCurrentStepAction';
import {
  Link,
  MonthlyVsYearlyHeader,
  MonthlyVsYearlyScrollbar,
  Title,
  Description,
  Divider
} from './styles';
import { YEARLY_PLAN_SELECTOR_STEP } from '../../../common/constants';

export const CompareYearlyAndMonthlyPlan: React.FC = () => {
  const getText = useGetText();
  const dispatch = useDispatch();

  const handleYearlyPlansClick = () => {
    if (dispatch) {
      dispatch(setCurrentStepAction(YEARLY_PLAN_SELECTOR_STEP));
    }
  };

  return (
    <div data-testid="yearly-plan-selector-compare-yearly-monthly-step">
      <Title>{getText('plans_selector.yearly.modals.title_switch_plan')}</Title>
      <Description>
        {getText('plans_selector.yearly.modals.compare_plans_to_change', {
          savings: '10%'
        })}
      </Description>
      <SwitchMonthlyToYearlySection />
      <Divider />
      <MonthlyVsYearlyScrollbar>
        <MonthlyVsYearlyHeader>
          <Title>
            {getText('plans_selector.yearly.modals.monthly_vs_yearly')}
          </Title>
          <Description>
            {getText('plans_selector.yearly.modals.compare_plans_to_change', {
              savings: '10%'
            })}
          </Description>

          <Link
            data-testid="see-all-yearly-plans-link"
            data-linkid="ii_plans"
            data-analytics-id="ii_plans"
            onClick={handleYearlyPlansClick}
          >
            {getText('plans_selector.yearly.modals.see_all_yearly_plans')}
          </Link>
        </MonthlyVsYearlyHeader>
        <YearlyPlanComparisonTable />
        <CompareYearlyPlanDisclaimer />
      </MonthlyVsYearlyScrollbar>
    </div>
  );
};
