import styled from 'styled-components';

export const ContentContainer = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const InfoContainer = styled.div`
  && {
  }
`;
