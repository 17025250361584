import { Plan } from '../types/plan';

export const isValidPlan = (planToValidate: Plan): planToValidate is Plan => {
  if (
    (planToValidate as Plan).guid &&
    (planToValidate as Plan).id &&
    (planToValidate as Plan).price &&
    (planToValidate as Plan).waiver &&
    (planToValidate as Plan).overageBlockPrice &&
    (planToValidate as Plan).overageBlockSize &&
    (planToValidate as Plan).pages &&
    ((planToValidate as Plan).planFlavor ||
      (planToValidate as Plan).frequency) &&
    (planToValidate as Plan).rollover
  )
    return true;

  return false;
};

export const isValidPlans = (plans: Plan[]) => {
  let result = true;
  plans.forEach((plan: Plan) => {
    if (!isValidPlan(plan)) {
      result = false;
    }
  });
  return result;
};
