import React from 'react';
import ChevronLeft from '@veneer/core/dist/scripts/icons/icon_chevron_left';
import ChevronRight from '@veneer/core/dist/scripts/icons/icon_chevron_right';
import { StyledArrow } from './styles';
interface IArrowButton {
  className?: string;
  onClick?: () => void;
  direction: string;
  isDesktop: boolean;
}

export const ArrowButton = ({
  className,
  onClick,
  direction,
  isDesktop
}: IArrowButton) => {
  return (
    <StyledArrow
      className={`${className} ${direction} arrow-button`}
      onClick={onClick}
      data-testid="arrow-button"
      isDesktop={isDesktop}
    >
      {direction === 'left' ? <ChevronLeft /> : <ChevronRight />}
    </StyledArrow>
  );
};
