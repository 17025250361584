import React from 'react';
import { useGetText } from '../../../hooks';
import { PlanWarningsContainer } from './styles';
import IconMinusCircle from '@veneer/core/dist/scripts/icons/icon_minus_circle';

export const PlanWarnings = () => {
  const getText = useGetText();

  return (
    <div>
      <PlanWarningsContainer>
        <IconMinusCircle
          customStyle={{ verticalAlign: 'bottom', marginRight: '4px' }}
          color="red4"
          filled
        />
        {`${getText('plans_selector.plan_modal.error')} `}
      </PlanWarningsContainer>
    </div>
  );
};
