import { LandingPagePlansAction } from '../types/landingPagePlansAction';

export const setAssetsProviderAction = (
  language: string,
  country: string
): LandingPagePlansAction => ({
  type: 'SET_ASSETS_PROVIDER',
  language,
  country
});
