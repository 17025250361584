import React from 'react';
import { useConsumerPlansState } from '../../../hooks/useConsumerPlansState';
import { useGetText } from '../../../hooks/useGetText';
import { Title, Circle } from './styles';

interface PlanNameProps {
  planName?: string;
  circlesNumber?: number;
  dataTestId?: string;
  isInkPaper?: boolean;
}

export const PlanName: React.FC<PlanNameProps> = ({
  planName,
  circlesNumber = 0,
  dataTestId,
  isInkPaper = false
}) => {
  const { program } = useConsumerPlansState();
  const getText = useGetText();
  const shouldShowPlanName = program !== 'i_toner' && planName;

  return (
    <div>
      {Array.from({ length: circlesNumber }, (_, i) => {
        return (
          <Circle
            key={i}
            filled
            size={15}
            program={isInkPaper ? 'i_ink_paper' : program}
          />
        );
      })}
      {shouldShowPlanName ? (
        <Title data-testid={`${dataTestId}-plan-name-title`}>
          {getText(`plans_selector.common.plans_frequency.${planName}`)}
        </Title>
      ) : (
        <div style={{ height: 30 }} />
      )}
    </div>
  );
};
