import styled from 'styled-components';

export type StyledArrowProps = {
  isDesktop?: boolean;
};

export const StyledArrow = styled.button<StyledArrowProps>`
  display: ${(props) => (props.isDesktop ? 'block' : 'none')};
  cursor: pointer;
  background-color: white;
  height: 42px;
  width: 44px;
  border: none;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.15);
  margin: 15px -25px 0 -25px;
  border-radius: 50%;
  padding-top: 5px;
  align-self: center !important;
  z-index: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;
