import styled from 'styled-components';
import primitives from '@veneer/primitives';
import IconCircle from '@veneer/core/dist/scripts/icons/icon_circle';
import { PLAN_PROGRAMMES } from '../../../common/constants';

export const Title = styled.h1`
  && {
    font-size: 16px;
    font-weight: 400;
    color: ${primitives.color.gray12};
    font-family: ${primitives.typography.family0};
    margin-top: 8px;
    margin-bottom: 0px;
    word-break: break-all;
    line-height: 20px;
  }
`;

type CircleProps = {
  program: string;
};

export const Circle = styled(IconCircle)<CircleProps>`
  && {
    margin-right: 6px;
    color: ${(props) => {
      switch (props.program) {
        case PLAN_PROGRAMMES.INSTANT_INK:
          return '#B655F5';
        case PLAN_PROGRAMMES.INSTANT_TONER:
          return '#4759F5';
        case PLAN_PROGRAMMES.SMB:
          return '#0096D6';
        case PLAN_PROGRAMMES.SMB_MONO:
          return '#231F20';
        default:
          return '#B655F5';
      }
    }};
  }
`;
