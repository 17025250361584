import styled from 'styled-components';
import primitives from '@veneer/primitives';

import { Resposive_Container } from '../../../../common/types/responsiveContainer';

export const StyledInlineError = styled.div`
  margin-top: 10px;
  line-height: 20px;
  text-align: center;
  color: ${primitives.color.red6};
`;

export const PlanContainer = styled.div<Resposive_Container>`
  padding: 0px;
  buttom: {
    bottom: ${(props) => (props.isDesktop ? `0` : `16px`)};
  }
`;
