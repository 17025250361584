import styled from 'styled-components';
import primitives from '@veneer/primitives';

interface ContainerProps {
  isPlansV1: boolean;
}
export const Container = styled.div<ContainerProps>`
  && {
    span {
      font-size: 14px;
      font-weight: ${(props) => (props.isPlansV1 ? '500' : '400')};
      line-height: 20px;
      font-family: ${primitives.typography.family0};
      color: ${(props) =>
        props.isPlansV1 ? primitives.color.gray8 : primitives.color.gray12};
    }

    a {
      font-size: 14px;
      font-weight: ${(props) => (props.isPlansV1 ? '500' : '400')};
      line-height: 20px;
      font-family: ${primitives.typography.family0};
      color: ${primitives.color.hpBlue7};
      margin-left: 3px;
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;
