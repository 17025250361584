import React from 'react';

import { Container, Circle, Title, Subtitle } from './styles';
import { useConsumerPlansState } from '../../../../../../hooks/useConsumerPlansState';
import { useGetText } from '../../../../../../hooks/useGetText';
import { useWindowSize } from '../../../../../../../V1/hooks';
import { MIN_TABLET_RESOLUTION } from '../../helpers/resolutionHelper';

export const TitleAndSubtitle: React.FC = () => {
  const { program, selectedPlan } = useConsumerPlansState();
  const getText = useGetText();
  const isInkPaper = selectedPlan?.isInkPaperPlan;
  const resolution = useWindowSize();
  const isMobile = resolution < MIN_TABLET_RESOLUTION;

  const TitleInfo = () => {
    if (selectedPlan.plan.frequency) {
      if (isInkPaper) {
        return getText(`plans_selector.modals.ink_plus_paper_monthly_plan`, {
          planName: getText(
            `plans_selector.common.plans_frequency.${selectedPlan.plan.frequency}`
          )
        });
      } else if (program === 'i_ink') {
        return getText(`plans_selector.modals.ink_monthly_plan`, {
          planName: getText(
            `plans_selector.common.plans_frequency.${selectedPlan.plan.frequency}`
          )
        });
      } else {
        return getText(`plans_selector.modals.toner_monthly_plan`, {
          planName: ''
        });
      }
    } else {
      return getText(`plans_selector.modals.toner_monthly_plan`, {
        planName: ''
      });
    }
  };
  return (
    <Container>
      {Array.from({ length: selectedPlan.index + 1 }, (_, i) => {
        return (
          <Circle
            key={i}
            filled
            size={15}
            program={isInkPaper ? 'i_ink_paper' : program}
            data-testid="plan-info-circle"
          />
        );
      })}
      <Title isMobile={isMobile}>{TitleInfo()}</Title>
      <Subtitle
        dangerouslySetInnerHTML={{
          __html: getText(
            'plans_selector.modals.print_up_to_x_pages_per_month_for_x_per_month',
            {
              pages: selectedPlan.plan.pages.toString(),
              price: selectedPlan.plan.price
            }
          )
        }}
      />
    </Container>
  );
};
