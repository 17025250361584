import styled from 'styled-components';
import { breakpointsHelper } from '../../../common/utils/breakpointsHelper';

export const CompareCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 24px;

  @media (max-width: ${breakpointsHelper.extra_small_575px}) {
    justify-content: center;
    flex-direction: column;
    margin-top: 0;
  }
`;
