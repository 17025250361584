import React, { useEffect, useState } from 'react';
import { StyledSlider, SliderCard, Container, FlexContainer } from './styles';
import { ArrowButton } from './ArrowButton';
import { useLandingPagePlansState } from '../../hooks/useLandingPagePlansState';

interface ScrollViewProps {
  children: React.ReactNode;
}

export const ScrollView: React.FC<ScrollViewProps> = ({ children }) => {
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const { program } = useLandingPagePlansState();

  const showArrows = () => {
    const sliderScroll = document.querySelector(
      `#styled-slider-scroll_${program}`
    );
    if (sliderScroll === null) return;

    if (sliderScroll.scrollLeft == 0) {
      setShowLeftArrow(false);
      setShowRightArrow(true);
    } else {
      setShowLeftArrow(true);

      if (
        sliderScroll.clientWidth + sliderScroll.scrollLeft + 1 >=
        sliderScroll.scrollWidth
      ) {
        setShowRightArrow(false);
      } else {
        setShowRightArrow(true);
      }
    }
  };

  useEffect(() => {
    const sliderScroll = document.querySelector(
      `#styled-slider-scroll_${program}`
    );
    if (sliderScroll === null) return;
    sliderScroll.addEventListener('scroll', showArrows, { passive: true });
  }, []);

  return (
    <FlexContainer>
      <Container data-testid="landing-page-plans-scrollview-container">
        {showLeftArrow && (
          <ArrowButton
            direction="left"
            onClick={() => {
              const scrollLeft = document.getElementById(
                `styled-slider-scroll_${program}`
              );
              if (scrollLeft === null) return;
              scrollLeft.scrollLeft -= 200;
            }}
          />
        )}

        <StyledSlider
          data-testid={`styled-slider-scroll_${program}`}
          id={`styled-slider-scroll_${program}`}
        >
          <SliderCard id={'sliderCard'}>{children}</SliderCard>
        </StyledSlider>

        {showRightArrow && (
          <ArrowButton
            direction="right"
            onClick={() => {
              const scrollRight = document.getElementById(
                `styled-slider-scroll_${program}`
              );
              if (scrollRight === null) return;
              scrollRight.scrollLeft += 200;
            }}
          />
        )}
      </Container>
    </FlexContainer>
  );
};
