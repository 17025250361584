import React from 'react';
import { Container, SelectPlanContainer } from './styles';
import { useDispatch, useGetText } from '../../hooks';
import Button from '@veneer/core/dist/scripts/button';
import { usePlanSelectorState } from '../../hooks';
import { savePlanAction } from '../../actions';
import { isSMBPlanProgram } from '../../../../common/lib/smbHelper';
import { ANALYTICS_EVENT_LABEL } from '../../../../common/constants';

const SelectPlanButton = () => {
  const getText = useGetText();
  const dispatch = useDispatch();
  const {
    selectedPlan,
    selectedPlanGUID,
    isSavingPlan,
    plansLoading,
    onCancel,
    trackClickEvent,
    program,
    savePlanError
  } = usePlanSelectorState();
  const isSMBProgram = isSMBPlanProgram(program);
  const isSavePlanDisabled = !selectedPlanGUID || isSavingPlan || plansLoading;

  return (
    <Container>
      <SelectPlanContainer program={program} savePlanError={savePlanError}>
        {isSMBProgram && (
          <Button
            data-analytics-id="ClosePlansButton"
            onClick={onCancel}
            appearance="secondary"
          >
            {getText('plans_selector.cancel')}
          </Button>
        )}
        <Button
          disabled={isSavePlanDisabled}
          loading={isSavingPlan}
          onClick={(e) => {
            trackClickEvent(ANALYTICS_EVENT_LABEL, 'select-plan-button');
            e.target['controlDetail'] = `Plan: ${selectedPlan.pages}`;
            dispatch(savePlanAction(selectedPlan));
          }}
          data-testid="select-plan-button"
          data-analytics-id="select-plan-button"
          data-linkid={ANALYTICS_EVENT_LABEL}
        >
          {getText('plans_selector.select_plan')}
        </Button>
      </SelectPlanContainer>
    </Container>
  );
};

export default SelectPlanButton;
