import { PlanSelectorState } from '../../../common/types/planSelectorState';
import {
  PlanSelectorAction,
  PlanSelectorActionType
} from '../../../common/types/planSelectorAction';
import { Plan } from '../../../common/types/plan';
import { AsyncDispatch } from '../hooks/useAsyncReducer';
import { isValidPlans } from '../../../common/lib/PlanValidator';

const {
  FETCH_PLANS,
  FETCH_PLANS_SUCCESS,
  FETCH_PLANS_FAIL,
  SET_ASSETS_PROVIDER,
  SET_CONTAINTER_SIZE,
  SET_SELECTED_PLAN_GUID,
  SET_DEFAULT_PLAN_GUID_INK,
  SET_DEFAULT_PLAN_GUID_TONER,
  SAVE_PLAN,
  SAVE_PLAN_SUCCESS,
  SAVE_PLAN_FAIL,
  CLEAR_SAVE_PLAN_ERROR,
  SET_SELECTED_PLAN,
  SET_SELECTED_PROGRAM
} = PlanSelectorActionType;

export const setAssetsProviderAction = (
  language: string,
  country: string
): PlanSelectorAction => ({
  type: SET_ASSETS_PROVIDER,
  language,
  country
});

export const setContainerSizeAction = (containerSize: string) => ({
  type: SET_CONTAINTER_SIZE,
  containerSize
});

export const setSelectedPlanGUIDAction = (selectedPlanGUID: string) => ({
  type: SET_SELECTED_PLAN_GUID,
  selectedPlanGUID
});

export const setSelectedPlanAction = (selectedPlan: Plan) => ({
  type: SET_SELECTED_PLAN,
  selectedPlan
});

export const setProgramAction = (program: string) => ({
  type: SET_SELECTED_PROGRAM,
  program
});

export const fetchPlansAction = () => {
  return async (
    dispatch: AsyncDispatch<PlanSelectorAction, PlanSelectorState>,
    getState: () => PlanSelectorState
  ) => {
    try {
      await dispatch({ type: FETCH_PLANS });
      const { getPlans, selectedPlanGUID } = getState();
      const plans = await getPlans();
      if (isValidPlans(plans)) {
        dispatch({ type: FETCH_PLANS_SUCCESS, plans });
        if (!selectedPlanGUID) {
          const defaultPlan = plans.find((plan) => plan.isDefault);
          dispatch({
            type: SET_DEFAULT_PLAN_GUID_INK,
            defaultPlanGUIDInk: defaultPlan?.guid
          });
          dispatch({
            type: SET_DEFAULT_PLAN_GUID_TONER,
            defaultPlanGUIDToner: defaultPlan?.guid
          });
          dispatch({ type: SET_SELECTED_PLAN, selectedPlan: defaultPlan });
        } else {
          const selectedPlan = plans.find(
            (plan) => plan.guid === selectedPlanGUID
          );
          dispatch({ type: SET_SELECTED_PLAN, selectedPlan });
        }
      } else {
        dispatch({
          type: FETCH_PLANS_FAIL,
          error: new Error('Fetched plans invalid')
        });
      }
      return plans;
    } catch (error) {
      dispatch({
        type: FETCH_PLANS_FAIL,
        error: new Error(`Error Fetching plans ${JSON.stringify(error)}`)
      });
    }
  };
};

export const savePlanAction = (plan: Plan) => {
  return async (
    dispatch: AsyncDispatch<PlanSelectorAction, PlanSelectorState>,
    getState: () => PlanSelectorState
  ) => {
    try {
      await dispatch({ type: SAVE_PLAN });
      await getState().onPlanChange(plan.id);
      dispatch({ type: SAVE_PLAN_SUCCESS, savedPlanGUID: plan.guid });
    } catch (error) {
      dispatch({
        type: SAVE_PLAN_FAIL,
        error: `Error saving plan - ${JSON.stringify(error)}`
      });
    }
  };
};

export const clearSavePlanError = () => ({
  type: CLEAR_SAVE_PLAN_ERROR
});
