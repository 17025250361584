import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const CardContainer = styled.div`
  && {
    margin-top: 12px;
    margin-bottom: 16px;

    padding: 12px;
    border-radius: 8px;
    border: 1px solid ${primitives.color.gray3};
    border-left-width: 8px;
    border-left-color: ${primitives.color.mediumOrchid6};
  }
`;

export const Divider = styled.span`
  && {
    border: 1px solid ${primitives.color.gray3};
    margin: 0 12px;
  }
`;

export const LargeFont = styled.span`
  && {
    font-weight: 400;
    font-size: 18px;
  }
`;
