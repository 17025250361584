import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { breakpointsHelper } from '../../../../../common/utils/breakpointsHelper';
import Select from '@veneer/core/dist/scripts/select';

export const TitleContainer = styled.div`
  && {
    display: flex;
    width: 100%;
    height: 96px;
    padding-left: 32px;
    align-items: center;
    background-color: ${primitives.color.white};
    border-top-left-radius: 12px;
    border-bottom: 1px solid ${primitives.color.gray2};

    @media (max-width: ${breakpointsHelper.large_max_1199px}) {
      width: 100%;
      height: 100%;
      background-color: ${primitives.color.gray0};
      padding-top: 16px;
      padding-left: 24px;
      border-bottom: none;
      border-top-right-radius: 12px;
    }

    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      width: 100%;
      height: 100%;
      background-color: ${primitives.color.gray0};
      padding-top: 16px;
      padding-left: 16px;
      border-bottom: none;
    }
  }
`;

export const Title = styled.div`
  && {
    font-family: ${primitives.typography.family0};
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;

    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;

export const ComboBoxContainer = styled.div`
  && {
    display: flex;
    width: 560px;
    background-color: ${primitives.color.gray0};
    height: 96px;
    justify-content: flex-start;
    align-items: center;
    border-top-right-radius: 12px;
    border-bottom: 1px solid ${primitives.color.gray2};

    @media (max-width: ${breakpointsHelper.large_max_1199px}) {
      width: 100%;
    }

    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      width: 100%;
    }
  }
`;

export const Combobox = styled(Select)`
  && {
    width: 496px;
    background-color: white;
    border-radius: 12px;
    margin: 0px 32px;

    @media (max-width: ${breakpointsHelper.large_max_1199px}) {
      margin: 0px 24px;
      width: 100%;
    }

    @media (max-width: 715px) {
      margin: 0px 16px;
      width: 295px;
    }
  }
`;
