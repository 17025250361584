import { createContext, Dispatch } from 'react';
import { DashboardPlansState } from '../types/dashboardPlansState';
import { DashboardPlansAction } from '../types/dashboardPlansAction';

type DashboardPlansContextType = {
  dashboardPlansState?: DashboardPlansState;
  dispatch?: Dispatch<DashboardPlansAction>;
};
export const DashboardPlansContext = createContext<DashboardPlansContextType>(
  {}
);
