import AssetsProviderFactory from '../../assets/AssetsProviderFactory';
import { LandingPagePlansAction } from '../types/landingPagePlansAction';
import { LandingPagePlansState } from '../types/landingPagePlansState';

export const landingPagesPlansInitialState = ({
  plans,
  country,
  language,
  defaultPlanGUIDInk = '',
  defaultPlanGUIDToner = '',
  program
}): LandingPagePlansState => ({
  plans,
  getText: AssetsProviderFactory.create(language, country).getText.bind(
    AssetsProviderFactory.create(language, country)
  ),
  program,
  defaultPlanGUIDInk,
  defaultPlanGUIDToner
});

export const landingPagePlansReducer = (
  state: LandingPagePlansState,
  action: LandingPagePlansAction
) => {
  let newState = state;

  if (action.type === 'SET_ASSETS_PROVIDER') {
    newState = {
      ...state,
      getText: AssetsProviderFactory.create(
        action.language,
        action.country
      ).getText.bind(
        AssetsProviderFactory.create(action.language, action.country)
      )
    };
  }
  return newState;
};
