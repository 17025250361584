import styled from 'styled-components';
import tokens from '@veneer/tokens';
import checkbox from '@veneer/core/dist/scripts/checkbox';
import Button from '@veneer/core/dist/scripts/button';

export const TOSCheckbox = styled(checkbox)`
  && {
    align-items: flex-start;
    margin-top: 16px;
    span {
      margin-top: 4px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: ${tokens.color.gray12};
      line-height: 20px;
      font-family: ${tokens.typography.family0};
    }
  }
`;
export const ButtonContainer = styled.div`
  && {
    display: flex;
    justify-content: flex-end;
    align-self: stretch;
    margin-top: 40px;

    & > *:not(:last-child) {
      margin-right: 16px;
      margin-top: 0;
    }

    @media (max-width: 575px) {
      flex-direction: column-reverse;

      & > *:not(:last-child) {
        margin-right: 0;
        margin-top: 16px;
      }
      button {
        width: 100%;
      }
    }
  }
`;

export const EnrollButton = styled(Button)`
  && {
    white-space: normal;
  }
`;
