import React from 'react';
import { DesktopPlanInfo } from './DesktopPlanInfo';
import { useWindowSize } from '../../../../../common/hooks/useWindowSize';
import {
  MIN_DESKTOP_RESOLUTION,
  MIN_TABLET_RESOLUTION
} from './common/helpers/resolutionHelper';

import { TabletPlanInfo } from './TabletPlanInfo';
import { MobilePlanInfo } from './MobilePlanInfo';

interface IndividualPlanInfoProps {
  setShowIndividualPlanInfo: (state: boolean) => void;
}

export const IndividualPlanInfo: React.FC<IndividualPlanInfoProps> = ({
  setShowIndividualPlanInfo
}) => {
  const resolution = useWindowSize();
  const isDesktop = resolution >= MIN_DESKTOP_RESOLUTION;
  const isTablet =
    resolution < MIN_DESKTOP_RESOLUTION && resolution >= MIN_TABLET_RESOLUTION;

  if (isDesktop) {
    return (
      <DesktopPlanInfo setShowIndividualPlanInfo={setShowIndividualPlanInfo} />
    );
  } else if (isTablet) {
    return (
      <TabletPlanInfo setShowIndividualPlanInfo={setShowIndividualPlanInfo} />
    );
  } else {
    return (
      <MobilePlanInfo setShowIndividualPlanInfo={setShowIndividualPlanInfo} />
    );
  }
};
