import React, { useEffect, useMemo, useReducer } from 'react';
import { DashboardPlansContext } from './context/dashboardPlansContext';
import {
  dashboardPlansInitialState,
  dashboardPlansReducer
} from './reducers/dashboardPlansReducer';
import { setAssetsProviderAction } from '../common/actions/setAssetsProviderAction';
import { setSelectedPlanAction } from './actions/setSelectedPlanAction';
import { setSubscriptionStateAction } from './actions/setSubscriptionStateAction';
import { setShowYearlyCardAction } from './actions/setShowYearlyCardAction';
import { setPausedPlanGUID } from './actions/setPausedPlanGUID';
import { setIsYearlyPlanSelectedAction } from './actions/setIsYearlyPlanSelectedAction';
import { DashboardPlansContent } from './components/DashboardPlanContent';
import { DashboardPlansProps } from './types/dashboardPlansProps';
import { findPlanBySavedPlanGUID } from './utils/findPlanBySavedPlanGUID';
import {
  jarvisPlansReducer,
  jarvisPlansInitialState
} from '../common/reducers/jarvisPlansReducer';
import { JarvisPlansContext } from '../common/context/jarvisPlanContext';

export const DashboardPlanSelector: React.FC<DashboardPlansProps> = (props) => {
  const [jarvisPlansState, jarvisDispatch] = useReducer(
    jarvisPlansReducer,
    jarvisPlansInitialState(props)
  );

  const [dashboardPlansState, dispatch] = useReducer(
    dashboardPlansReducer,
    dashboardPlansInitialState(props)
  );

  useEffect(() => {
    dispatch(setAssetsProviderAction(props.language, props.country));
  }, [props.language, props.country]);

  useEffect(() => {
    dispatch(setSubscriptionStateAction(props.subscriptionState));
  }, [props.subscriptionState]);

  useEffect(() => {
    dispatch(setPausedPlanGUID(props.pausedPlanGUID));
  }, [props.pausedPlanGUID]);

  useEffect(() => {
    dispatch(setIsYearlyPlanSelectedAction(props.isYearlyPlanSelected));
  }, [props.isYearlyPlanSelected]);

  useEffect(() => {
    dispatch(setShowYearlyCardAction(props.showYearlyCard));
  }, [props.showYearlyCard]);

  useEffect(() => {
    const selected = findPlanBySavedPlanGUID(props.plans, props.savedPlanGUID);
    dispatch(
      setSelectedPlanAction({
        id: selected.plan.id,
        index: selected.index,
        guid: selected.plan.guid,
        plan: selected.plan,
        isLoading: false
      })
    );
  }, [props.savedPlanGUID]);

  const contextValue = useMemo(
    () => ({ dashboardPlansState, dispatch }),
    [dashboardPlansState, dispatch]
  );

  const jarvisContextValue = useMemo(
    () => ({ jarvisPlansState, dispatch: jarvisDispatch }),
    [jarvisPlansState, jarvisDispatch]
  );

  return (
    <JarvisPlansContext.Provider value={jarvisContextValue}>
      <DashboardPlansContext.Provider value={contextValue}>
        <DashboardPlansContent />
      </DashboardPlansContext.Provider>
    </JarvisPlansContext.Provider>
  );
};

export default DashboardPlanSelector;
