import styled from 'styled-components';

export const PlanCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(221px, 1fr));
  gap: 24px;

  @media (min-width: 1200px) and (max-width: 1279px) {
    grid-template-columns: repeat(3, minmax(219px, 1fr));
  }

  @media (max-width: 1023px) {
    grid-template-columns: repeat(3, minmax(219px, 1fr));
  }

  @media (min-width: 500px) and (max-width: 767px) {
    grid-template-columns: repeat(2, minmax(221px, 1fr));
  }

  @media (max-width: 499px) {
    grid-template-columns: repeat(1, minmax(221px, 1fr));
  }
`;
