import React from 'react';

import { PlanDetailsContainer } from './styles';
import { useGetText } from '../../../../../../hooks/useGetText';
import { useConsumerPlansState } from '../../../../../../hooks/useConsumerPlansState';

export const PlanDetails: React.FC = () => {
  const { program, selectedPlan } = useConsumerPlansState();
  const getText = useGetText();
  const isInkPaper = selectedPlan?.isInkPaperPlan;

  const cartridgesContent = () => {
    if (isInkPaper) {
      return getText(
        'plans_selector.modals.ink_cartridges_and_paper_sent_automatically_when_you_run_low'
      );
    } else if (program === 'i_ink') {
      return getText(
        'plans_selector.modals.ink_cartridges_sent_automatically_when_you_run_low'
      );
    } else {
      return getText(
        'plans_selector.modals.toner_cartridges_sent_automatically_when_you_run_low'
      );
    }
  };

  const returnAndRecyclingContent = () => {
    if (isInkPaper || program === 'i_ink') {
      return getText(
        'plans_selector.modals.includes_used_ink_cartridge_return_and_recycling'
      );
    } else {
      return getText(
        'plans_selector.modals.includes_used_toner_cartridge_return_and_recycling'
      );
    }
  };
  return (
    <PlanDetailsContainer>
      <span>{getText('plans_selector.modals.plan_details')}</span>
      <ul>
        <li>{cartridgesContent()}</li>
        <li>{returnAndRecyclingContent()}</li>
        <li>
          {getText('plans_selector.modals.roll_over_up_to_x_pages', {
            pages: `${selectedPlan.plan.rollover}`
          })}
        </li>
        <li>
          {getText('plans_selector.modals.additional_sets_of_x_pages_for_x', {
            pages: selectedPlan.plan.overageBlockSize.toString(),
            price: selectedPlan.plan.overageBlockPrice
          })}
        </li>
        <li>{getText('plans_selector.modals.renews_monthly')}</li>
        <li>
          {getText('plans_selector.modals.change_pause_or_cancel_anytime')}
        </li>
      </ul>
    </PlanDetailsContainer>
  );
};
