import React from 'react';
import useMediaQuery from '@veneer/core/dist/scripts/utils/use_media_query';
import { WideCard } from '../WideCard';
import { YearlyPlansCard } from '../YearlyPlansCard';
import { useGetText } from '../../../common/hooks/useGetText';
import { Carousel } from '../Carousel';
import { PlanCardContainer, Title, BoldText } from './styles';
import { breakpointsHelper } from '../../../common/utils/breakpointsHelper';
import {
  parseCurrency,
  formatCurrency
} from '../../../common/lib/moneyHelpers';
import { useYearlyPlanSelectorState } from '../../hooks/useYearlyPlanSelectorState';

export const AllYearlyPlansSelector: React.FC = () => {
  const getText = useGetText();
  const { plans, yearlyPlans, correspondingYearlyPlan, currentPlan } =
    useYearlyPlanSelectorState();
  const isTablet = useMediaQuery(
    `(max-width: ${breakpointsHelper.medium_min_768px})`
  );
  const isMobile = useMediaQuery(
    `(max-width: ${breakpointsHelper.extra_small_575px})`
  );

  const findCorrespondingPlan = (pages) => {
    return plans.find((plan) => plan.pages * 12 === pages);
  };

  const calculateSavings = (annualPrice: string): string => {
    const { numericValue, symbol, decimalSeparator } =
      parseCurrency(annualPrice);
    const savings = numericValue * 0.1;
    return formatCurrency(savings, symbol, decimalSeparator);
  };

  const calculateYearlyPriceWithoutSavings = (monthlyPrice: string): string => {
    const { numericValue, symbol, decimalSeparator } =
      parseCurrency(monthlyPrice);
    const price = numericValue * 12;
    return formatCurrency(price, symbol, decimalSeparator);
  };

  const PlanCards = () => {
    return (
      <PlanCardContainer data-testid="cards-container">
        {yearlyPlans.map((yearlyPlan, index) => {
          const monthlyPlan = findCorrespondingPlan(yearlyPlan.pages);
          if (monthlyPlan) {
            const yearlyPriceWithoutSavings =
              monthlyPlan &&
              calculateYearlyPriceWithoutSavings(monthlyPlan.price);
            const savingsPerYear =
              monthlyPlan && calculateSavings(yearlyPriceWithoutSavings);
            return (
              <YearlyPlansCard
                key={index}
                name={monthlyPlan?.frequency}
                numberOfCircles={index + 1}
                yearlyPlan={yearlyPlan}
                pricePerYear={yearlyPriceWithoutSavings}
                savingsPercentage={savingsPerYear}
                hasTag={yearlyPlan.guid === correspondingYearlyPlan.guid}
              />
            );
          }
        })}
      </PlanCardContainer>
    );
  };

  return (
    <div data-testid="yearly-plan-selector-select-yearly-step">
      <Title>
        {getText('plans_selector.yearly.modals.all_yearly_plans.title')}
      </Title>
      <WideCard
        pagesPerMonth={currentPlan.plan.pages.toString()}
        pricePerMonth={currentPlan.plan.price}
      />
      {isTablet ? (
        <PlanCards />
      ) : (
        <Carousel>
          <PlanCards />
        </Carousel>
      )}
      <p>
        <BoldText $isMobile={isMobile}>
          {getText('plans_selector.yearly.modals.all_yearly_plans.print_more')}
          &nbsp;
        </BoldText>
        {getText('plans_selector.yearly.modals.all_yearly_plans.renew_plan')}
      </p>
    </div>
  );
};
