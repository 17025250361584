import styled from 'styled-components';
import ChevronLeft from '@veneer/core/dist/scripts/icons/icon_chevron_left';
import ChevronRight from '@veneer/core/dist/scripts/icons/icon_chevron_right';

type ArrowsContainerProps = {
  direction: string;
};

export const StyledArrowContainer = styled.div<ArrowsContainerProps>`
  && {
    display: flex;
    width: 50px;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-right: ${(props) => (props.direction === 'left' ? 0 : -50)}px;
    margin-left: ${(props) => (props.direction === 'right' ? 0 : -50)}px;
    z-index: 1;
  }
`;

export const StyledArrow = styled.button`
  && {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 50%;
    z-index: 1;
    padding: 0;
  }
`;

export const IconLeft = styled(ChevronLeft)`
  &&& {
    color: #0073a8;
    margin: 0;
  }
`;

export const IconRight = styled(ChevronRight)`
  &&& {
    color: #0073a8;
    margin: 0;
  }
`;
