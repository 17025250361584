import React from 'react';
import {
  StyledArrow,
  IconLeft,
  IconRight,
  StyledArrowContainer
} from './styles';
interface IArrowButton {
  onClick?: () => void;
  direction: string;
}

export const ArrowButton = ({ onClick, direction }: IArrowButton) => {
  return (
    <StyledArrowContainer
      direction={direction}
      data-testid="yearly-plan-arrow-container"
    >
      <StyledArrow
        onClick={onClick}
        data-testid={'landing-page-plans-arrow-button-' + direction}
      >
        {direction === 'left' ? <IconLeft /> : <IconRight />}
      </StyledArrow>
    </StyledArrowContainer>
  );
};
