import React from 'react';
import { useGetText } from '../../hooks/useGetText';

import {
  Container,
  PagesContainer,
  PriceContainer,
  PagesTitle,
  PriceTitleContainer,
  PriceTitle
} from './styles';

interface PagesPriceInfoProps {
  pages: number;
  price: string;
  priceInkWithPaper: string;
  dataTestId?: string;
}

export const PaperPagesPriceInfo: React.FC<PagesPriceInfoProps> = ({
  pages,
  price,
  priceInkWithPaper,
  dataTestId
}) => {
  const getText = useGetText();
  return (
    <Container>
      <PagesContainer data-testid={`${dataTestId}-pages-container`}>
        <PagesTitle data-testid={`${dataTestId}-pages-title`}>
          {pages}
        </PagesTitle>
        <h2>{getText('plans_selector.sheets_per_month')}</h2>
      </PagesContainer>

      <PriceContainer data-testid={`${dataTestId}-price-container`}>
        <span>{getText('plans_selector.add_paper_for')}</span>
        <PriceTitleContainer>
          <PriceTitle data-testid={`${dataTestId}-price-title`}>
            +{price}
          </PriceTitle>
          <h2>{getText('plans_selector.plan_card.per_month')}</h2>
        </PriceTitleContainer>
        <span style={{ color: '#212121' }}>
          {getText('plans_selector.price_for_ink_and_paper', {
            price: priceInkWithPaper
          })}
        </span>
      </PriceContainer>
    </Container>
  );
};
