import { PlanSelectorActionType } from '../../../common/types/planSelectorAction';
import { Plan } from '../types/consumerPlansState';

export const setSelectedPlanAction = (selectedPlan: {
  id: number;
  index: number;
  guid: string;
  plan: Plan;
  isInkPaperPlan: boolean;
}) => ({
  type: PlanSelectorActionType.SET_SELECTED_PLAN,
  selectedPlan
});
