import React, { useEffect, useState } from 'react';
import { StyledSlider, SliderCard, Container } from './styles';
import { ArrowButton } from '../ArrowButton';
import { useWindowSize } from '../../hooks';
import { TABLET_LANDSCAPE_MAX } from '../../../../common/constants';

interface IGenericSlider {
  className?: string;
  children?: JSX.Element[];
}

export const GenericSlider = ({ className, children }: IGenericSlider) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const resolution = useWindowSize();

  useEffect(() => {
    setIsDesktop(resolution >= TABLET_LANDSCAPE_MAX);
  }, [resolution]);

  const showArrows = () => {
    const sliderScroll = document.querySelector('#styled-slider-scroll');
    if (sliderScroll.scrollLeft == 0) {
      setShowLeftArrow(false);
      setShowRightArrow(true);
    } else {
      setShowLeftArrow(true);

      if (
        sliderScroll.clientWidth + sliderScroll.scrollLeft + 1 >=
        sliderScroll.scrollWidth
      ) {
        setShowRightArrow(false);
      } else {
        setShowRightArrow(true);
      }
    }
  };

  useEffect(() => {
    document
      .querySelector('#styled-slider-scroll')
      .addEventListener('scroll', showArrows, { passive: true });
  }, []);

  return (
    <Container className={className}>
      {showLeftArrow && (
        <ArrowButton
          direction="left"
          isDesktop={isDesktop}
          onClick={() => {
            document.getElementById('styled-slider-scroll').scrollLeft -= 200;
          }}
        />
      )}

      <StyledSlider id={'styled-slider-scroll'} isDesktop={isDesktop}>
        {React.Children.map(children, (child) => (
          <SliderCard id={'sliderCard'}>{child}</SliderCard>
        ))}

        {isDesktop && (
          <SliderCard id={'sliderCard'}>
            <div style={{ width: 130 }} />
          </SliderCard>
        )}
      </StyledSlider>

      {showRightArrow && (
        <ArrowButton
          direction="right"
          isDesktop={isDesktop}
          onClick={() => {
            document.getElementById('styled-slider-scroll').scrollLeft += 200;
          }}
        />
      )}
    </Container>
  );
};
