import React from 'react';
import {
  Container,
  LeftContentContainer,
  RightContentContainer
} from './styles';

export const CardContentContainer: React.FC<{
  dataTestId?: string;
  isComboBoxSection?: boolean;
  applyTopRightBorder?: boolean;
  applyBottomRightBorder?: boolean;
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
}> = ({
  dataTestId,
  isComboBoxSection,
  leftContent,
  rightContent,
  applyTopRightBorder,
  applyBottomRightBorder
}) => {
  return (
    <Container
      isComboBoxSection={isComboBoxSection}
      data-testid={`card-content-container-${dataTestId}`}
    >
      <LeftContentContainer
        data-testid={`card-left-content-container-${dataTestId}`}
      >
        {leftContent}
      </LeftContentContainer>
      <RightContentContainer
        applyTopRightBorder={applyTopRightBorder}
        applyBottomRightBorder={applyBottomRightBorder}
        data-testid={`card-right-content-container-${dataTestId}`}
      >
        {rightContent}
      </RightContentContainer>
    </Container>
  );
};
