export function parseCurrency(value: string): {
  numericValue: number;
  symbol: string;
  decimalSeparator: string;
} {
  if (!value) return;

  const symbol = value.match(/[^\d.,\s]/g)?.join('') || '';
  const hasComma = value.includes(',');
  const hasDot = value.includes('.');
  let numericValue;
  let decimalSeparator;

  if (hasComma && hasDot) {
    if (value.indexOf(',') < value.indexOf('.')) {
      numericValue = parseFloat(value.replace(/[^0-9,]/g, '').replace(',', ''));
      decimalSeparator = '.';
    } else {
      numericValue = parseFloat(
        value
          .replace(/[^0-9.]/g, '')
          .replace('.', '')
          .replace(',', '.')
      );
      decimalSeparator = ',';
    }
  } else if (hasComma) {
    numericValue = parseFloat(value.replace(/[^0-9,]/g, '').replace(',', '.'));
    decimalSeparator = ',';
  } else {
    numericValue = parseFloat(value.replace(/[^0-9.]/g, ''));
    decimalSeparator = '.';
  }

  return { numericValue, symbol, decimalSeparator };
}

export function formatCurrency(
  value: number,
  symbol: string,
  decimalSeparator: string
): string {
  if (value == null) return;

  let formattedValue = value.toFixed(2);
  if (decimalSeparator === ',') {
    formattedValue = formattedValue.replace('.', ',');
    formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  } else {
    formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return `${symbol}${formattedValue}`;
}
