import styled from 'styled-components';
import primitives from '@veneer/primitives';
import Scrollbar from '@veneer/core/dist/scripts/scrollbar';
import { breakpointsHelper } from '../../../common/utils/breakpointsHelper';

export const Link = styled.span`
  && {
    color: ${primitives.color.hpBlue7};
    cursor: pointer;
  }
`;

export const MonthlyVsYearlyHeader = styled.div`
  && {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;

    @media (max-width: ${breakpointsHelper.medium_max_991px}) {
      margin-bottom: 16px;
    }

    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      margin-bottom: 14px;
    }
  }
`;

export const MonthlyVsYearlyScrollbar = styled(Scrollbar)`
  && {
    height: 277px;

    @media (max-width: ${breakpointsHelper.medium_max_991px}) {
      height: 484px;
    }

    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      height: 399px;
    }
  }
`;

export const Title = styled.h2`
  && {
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;

    @media (max-width: ${breakpointsHelper.medium_max_991px}) {
      font-size: 32px;
      line-height: 40px;
    }

    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const Description = styled.span`
  && {
    margin: 4px 0;
    font-size: 16px;
    line-height: 24px;

    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const Divider = styled.hr`
  && {
    margin: 32px 0 18px 0;
    height: 0px;
    border: 0;
    border-top: 1px solid ${primitives.color.gray3};
  }
`;
