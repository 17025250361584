import React from 'react';
import { ContentContainer, InfoContainer } from './styles';
import { TitleAndSubtitle } from '../common/components/TitleAndSubtitle';
import { TrialInfo } from '../common/components/TrialInfo';
import { PlanDetails } from '../common/components/PlanDetails';
import { PlanImage } from '../common/components/PlanImage';
import { PlanInfoButtons } from '../common/components/PlanInfoButtons';

interface TabletPlanInfoProps {
  setShowIndividualPlanInfo: (state: boolean) => void;
}

export const TabletPlanInfo: React.FC<TabletPlanInfoProps> = ({
  setShowIndividualPlanInfo
}) => {
  return (
    <div data-testid="plans-tablet-plan-info">
      <InfoContainer>
        <TitleAndSubtitle />
        <TrialInfo />
        <ContentContainer>
          <PlanDetails />
          <PlanImage />
        </ContentContainer>
      </InfoContainer>
      <PlanInfoButtons setShowIndividualPlanInfo={setShowIndividualPlanInfo} />
    </div>
  );
};
