import styled from 'styled-components';
import primitives from '@veneer/primitives';
import IconCircle from '@veneer/core/dist/scripts/icons/icon_circle';
import { getColor } from '../../../../../../../../common/utils/colorSelector';

export const Container = styled.div`
  && {
  }
`;

type CircleProps = {
  program: string;
};
export const Circle = styled(IconCircle)<CircleProps>`
  && {
    margin-right: 6px;
    color: ${(props) => getColor(props.program)};
  }
`;

interface TitleProps {
  isMobile?: boolean;
}

export const Title = styled.h1<TitleProps>`
  && {
    font-size: ${({ isMobile }) => (isMobile ? '24px' : '32px')};
    font-weight: 400;
    color: ${primitives.color.gray12};
    line-height: 40px;
    font-family: ${primitives.typography.family0};
    margin-top: 4px;
    margin-bottom: 0px;
  }
`;

export const Subtitle = styled.h2`
  &&& {
    font-size: 18px;
    font-weight: 400;
    color: ${primitives.color.gray12};
    line-height: 24px;
    font-family: ${primitives.typography.family0};
    margin-top: 4px;
    margin-bottom: 0px;

    b {
      font-weight: 700;
      font-family: ${primitives.typography.family0};
    }
  }
`;
