import styled from 'styled-components';

export const SliderCard = styled.div`
  display: flex;
  width: 100%;
  outline: none;
  &:focus {
    outline: none;
  }
`;

export const Container = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  div::-webkit-scrollbar {
    display: none;
  }

  div {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export type SliderContainerProps = {
  isDesktop?: boolean;
};

export const StyledSlider = styled.div<SliderContainerProps>`
  width: 100%;
  max-width: 860px;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  scroll-behavior: smooth;
  flex-direction: ${(props) => (props.isDesktop ? 'row' : 'column')};
  padding: ${(props) => (props.isDesktop ? '0 0 24px 2px' : '16px 2px 24px 0')};
`;
