import React from 'react';
import { StyledSlider, SliderCard, Container, FlexContainer } from './styles';
import { ArrowButton } from './ArrowButton';

interface CarouselProps {
  children: React.ReactNode;
}

export const Carousel: React.FC<CarouselProps> = ({ children }) => {
  return (
    <FlexContainer data-testid="yearly-plan-carousel-container">
      <Container data-testid="landing-page-plans-scrollview-container">
        <ArrowButton
          direction="left"
          onClick={() => {
            const scrollLeft = document.getElementById('carousel-plans-card');
            if (scrollLeft === null) return;
            scrollLeft.scrollLeft -= 200;
          }}
        />

        <StyledSlider
          data-testid={'carousel-plans-card'}
          id={'carousel-plans-card'}
        >
          <SliderCard id={'sliderCard'}>{children}</SliderCard>
        </StyledSlider>
        <ArrowButton
          direction="right"
          onClick={() => {
            const scrollRight = document.getElementById('carousel-plans-card');
            if (scrollRight === null) return;
            scrollRight.scrollLeft += 200;
          }}
        />
      </Container>
    </FlexContainer>
  );
};
