import styled, { css } from 'styled-components';
import VeneerButton from '@veneer/core/dist/scripts/button';

interface ButtonProps {
  $isMobile?: boolean;
}

export const ButtonsContainer = styled.div<ButtonProps>`
  && {
    display: flex;

    ${({ $isMobile }) => {
      if ($isMobile) {
        return css`
          margin-top: 24px;
          flex-direction: column-reverse;
        `;
      }
      return css`
        margin-top: 32px;
        justify-content: flex-end;
      `;
    }}
  }
`;

export const BackButton = styled(VeneerButton)<ButtonProps>`
  && {
    ${({ $isMobile }) => {
      if ($isMobile) {
        return css`
          margin-top: 16px;
        `;
      }
      return css`
        margin-right: 16px;
      `;
    }};
  }
`;

export const SelectButton = styled(VeneerButton)`
  && {
    justify-self: end;
  }
`;
