import styled from 'styled-components';
import VeneerTabs from '@veneer/core/dist/scripts/tabs';
import primitives from '@veneer/primitives';

interface TabsProps {
  $hasInkPaperTab: boolean;
}
export const Tabs = styled(VeneerTabs)<TabsProps>`
  && {
    #i_ink_paper {
      color: ${primitives.color.gray12};

      img {
        padding-bottom: 6px;
      }

      &::after {
        background-color: ${(props) =>
          props.selectedTabId === 'i_ink_paper'
            ? primitives.color.lavender5
            : 'transparent'};
      }
      &:hover {
        background-color: transparent;
      }
    }

    #i_ink {
      color: ${(props) =>
        !props.$hasInkPaperTab && props.selectedTabId === 'i_ink'
          ? primitives.color.mediumOrchid6
          : primitives.color.gray12};

      &::after {
        background-color: ${(props) =>
          props.selectedTabId === 'i_ink'
            ? primitives.color.mediumOrchid6
            : 'transparent'};
      }
      &:hover {
        background-color: transparent;
      }
    }

    #i_toner {
      color: ${(props) =>
        !props.$hasInkPaperTab && props.selectedTabId === 'i_toner'
          ? primitives.color.royalBlue7
          : primitives.color.gray12};

      &::after {
        background-color: ${(props) =>
          props.selectedTabId === 'i_toner'
            ? primitives.color.royalBlue7
            : 'transparent'};
      }
      &:hover {
        background-color: transparent;
      }
    }
  }
`;
