import styled from 'styled-components';
import VeneerRadioButtons from '@veneer/core/dist/scripts/deprecated_radio_buttons';
import { getColor } from '../../../../../../common/utils/colorSelector';

interface IPlanCardProps {
  program: string;
  isSelected: boolean;
  isHPX?: boolean;
}

export const PlanCard = styled.div<IPlanCardProps>`
  && {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 132px;
    border-radius: 8px;
    border: ${(props) => (props.isHPX && props.isSelected ? '2px' : '1px')}
      solid
      ${(props) => (props.isSelected ? getColor(props.program) : '#dbdbdb')};
    margin: 8px 0px;
    background-color: #ffffff;
    transition: border 1s;
  }
  cursor: pointer;
`;

export const PlanSelectionContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const InfoContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    padding: 16px 12px 0px 12px;
    height: 100%;
    margin-bottom: 16px;
  }
`;

export const RadioWithInfo = styled.div`
  && {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
`;

type ColorContainerProps = {
  program: string;
  isVisible?: boolean;
};

export const ColorContainer = styled.div<ColorContainerProps>`
  && {
    background-color: ${(props) => getColor(props.program)};
    height: 100%;
    width: ${(props) => (props.isVisible ? '16px' : '0px')};
    opacity: ${(props) => (props.isVisible ? '1' : '0')};
    border-radius: 7px 0px 0px 7px;
    transition: width 1s, opacity 1s;
  }
`;

export const RadioButtons = styled(VeneerRadioButtons)`
  && {
    margin-top: auto;
    margin-bottom: 18px;
    margin-right: 16px;
  }
`;

export const PlanTagContainer = styled.div`
  && {
    margin-bottom: auto;
    width: 100%;
  }
`;
