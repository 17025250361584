import { createContext } from 'react';
import { PlanSelectorAction } from '../../../common/types/planSelectorAction';
import { PlanSelectorState } from '../../../common/types/planSelectorState';
import { AsyncDispatch } from '../hooks/useAsyncReducer';

type PlanSelectorContextType = {
  planSelectorState?: PlanSelectorState;
  dispatch?: AsyncDispatch<PlanSelectorAction, PlanSelectorState>;
};

export const PlanSelectorContext = createContext<PlanSelectorContextType>({});
