import React from 'react';
import { PlansCard, ColorContainer, InfoContainer } from './styles';
import { PlanName } from '../PlanName';
import { PagesPriceInfo } from '../PagesPriceInfo';
import { PaperPagesPriceInfo } from '../PaperPagesPriceInfo';
import { PlanTag } from '../PlanTag';
import { useLandingPagePlansState } from '../../hooks/useLandingPagePlansState';
import { useGetText } from '../../hooks/useGetText';
import { PLAN_PROGRAMMES } from '../../../common/constants';

export const PlanCards: React.FC = () => {
  const getText = useGetText();
  const { program, plans, defaultPlanGUIDInk, defaultPlanGUIDToner } =
    useLandingPagePlansState();
  const isConsumerInk = program === PLAN_PROGRAMMES.INSTANT_INK;
  const defaultPlanGUID = isConsumerInk
    ? defaultPlanGUIDInk
    : defaultPlanGUIDToner;
  const tagName = isConsumerInk
    ? getText('plans_selector.common.most_popular')
    : getText('plans_selector.common.recommended');
  return (
    <>
      {plans.map((plan, index) => {
        return (
          <PlansCard data-testid={'lp-plans-plan-card-' + index} key={index}>
            <InfoContainer>
              <PlanName
                dataTestId={'lp-plans-plan-card-' + index}
                planName={plan.frequency}
                circlesNumber={index + 1}
              />
              {program === PLAN_PROGRAMMES.PAPER ? (
                <PaperPagesPriceInfo
                  dataTestId={'lp-plans-plan-card-' + index}
                  pages={plan.pages}
                  price={plan.price}
                  priceInkWithPaper={plan.priceInkWithPaper}
                />
              ) : (
                <PagesPriceInfo
                  dataTestId={'lp-plans-plan-card-' + index}
                  pages={plan.pages}
                  price={plan.price}
                />
              )}
            </InfoContainer>
            <div style={{ marginTop: 'auto', paddingTop: 12 }}>
              {plan.guid === defaultPlanGUID && (
                <PlanTag
                  dataTestId={'lp-plans-plan-card-' + index}
                  tagTitle={tagName}
                />
              )}
              <ColorContainer program={program} />
            </div>
          </PlansCard>
        );
      })}
    </>
  );
};
