import styled from 'styled-components';
import { getColor } from '../../../common/utils/colorSelector';
import primitives from '@veneer/primitives';
import paperCardImage from '../../../../imgs/paper-card-image.png';
import paperCardMobileImage from '../../../../imgs/paper-card-image-mobile.png';
import { breakpointsHelper } from '../../../common/utils/breakpointsHelper';

export const PaperCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 340px;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  background-color: #ffffff;
  cursor: pointer;

  @media (max-width: ${breakpointsHelper.extra_small_575px}) {
    width: 100%;
    margin-right: 0;
    justify-self: center;
    min-height: 0;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  background-color: #e6e6e6;
  border-radius: 8px 8px 0px 0px;
`;

export const PaperImage = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  background-position: center;
  background-size: cover;
  background-image: url(${paperCardImage});
  border-radius: 8px 8px 0px 0px;

  @media (max-width: ${breakpointsHelper.extra_small_575px}) {
    background-image: url(${paperCardMobileImage});
    margin-right: 17px;
  }
`;

export const TitleContainer = styled.div`
  &&& {
    h1 {
      font-size: 20px;
      font-weight: 400;
      color: ${primitives.color.gray12};
      line-height: 28px;
      font-family: ${primitives.typography.family0};
      margin-top: 16px;
      margin-left: 16px;
      margin-right: 16px;
      word-break: normal;
    }
  }
`;

export const PriceContainer = styled.div`
  &&& {
    h2 {
      font-size: 14px;
      font-weight: 400;
      color: ${primitives.color.gray12};
      line-height: 20px;
      font-family: ${primitives.typography.family0};
      margin-top: 8px;
      padding-right: 16px;
      padding-left: 16px;
      word-break: normal;
    }
  }
`;

export const Description = styled.span`
  && {
    font-size: 12px;
    font-weight: 400;
    color: ${primitives.color.gray12};
    line-height: 16px;
    font-family: ${primitives.typography.family0};
    margin-top: 4px;
    padding-right: 16px;
    padding-left: 16px;
    word-break: normal;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: auto;
  padding: 16px 16px 0px 16px;

  button {
    width: 100%;
    height: 42px;
    border-radius: 8px;

    span {
      white-space: normal;
    }
  }

  @media (max-width: ${breakpointsHelper.extra_small_575px}) {
    margin-top: 0;
  }
`;

type ColorContainerProps = {
  program: string;
};

export const ColorContainer = styled.div<ColorContainerProps>`
  && {
    width: 100%;
    height: 16px;
    border-radius: 0px 0px 7px 7px;
    background-color: ${(props) => getColor(props.program)};
  }
`;
