import { createContext, Dispatch } from 'react';
import { planSelectorV3State } from '../types/planSelectorV3State';
import { planSelectorV3Action } from '../types/planSelectorV3Action';

type planSelectorV3ContextType = {
  planSelectorV3State?: planSelectorV3State;
  dispatch?: Dispatch<planSelectorV3Action>;
};
export const planSelectorV3Context = createContext<planSelectorV3ContextType>(
  {}
);
