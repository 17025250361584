import React from 'react';
import { useGetText } from '../../hooks/useGetText';
import { TagContainer, TagTitle } from './styles';

interface PlanTagProps {
  tagTitle: string;
  color?: string;
  dataTestId?: string;
  isSelected?: boolean;
}
export const PlanTag: React.FC<PlanTagProps> = ({
  tagTitle,
  dataTestId,
  isSelected = false
}) => {
  const getText = useGetText();
  const tagTitleName = getText(`plans_selector.common.${tagTitle}`);
  return (
    <TagContainer
      data-testid={`${dataTestId}-plan-tag-container`}
      isSelected={isSelected}
    >
      <TagTitle
        isSelected={isSelected}
        data-testid={`${dataTestId}-plan-tag-title`}
      >
        {tagTitleName}
      </TagTitle>
    </TagContainer>
  );
};
