import React from 'react';
import Tooltip from '@veneer/core/dist/scripts/tooltip';
import IconInfo from '@veneer/core/dist/scripts/icons/icon_info';
import { useGetText, usePlanSelectorState } from '../../../hooks';
import { PlanLabelsContainer } from './styles';
import { useGetSelectedPlan } from '../../../hooks/useGetSelectedPlan';
import { isValidPlan } from '../../../../../common/lib/PlanValidator';
import { PriceUpdateNotice } from '../../../../common/components/PriceUpdateNotice';

export const PlanLabels = () => {
  const getText = useGetText();
  const selectedPlan = useGetSelectedPlan();
  const { plans, program, showPriceIncreaseInfo } = usePlanSelectorState();
  const isPriceIncreaseInfo = showPriceIncreaseInfo && program === 'i_ink';

  if (!selectedPlan || !isValidPlan(selectedPlan)) {
    return (
      isPriceIncreaseInfo && (
        <PlanLabelsContainer>
          <PriceUpdateNotice isPlansV1 getText={getText} />
        </PlanLabelsContainer>
      )
    );
  }

  const filteredPlans = plans.filter((plan) => plan.program === program);

  const overageBlockSizes = filteredPlans.map((plan) => plan.overageBlockSize);
  const planRollOvers = filteredPlans.map((plan) => plan.rollover);
  const planPages = filteredPlans.map((plan) => plan.pages);
  const overageBlockLowest = Math.min(...overageBlockSizes);
  const overageBlockHighest = Math.max(...overageBlockSizes);
  const rolloverHighest = Math.max(...planRollOvers);
  const pagesHighest = Math.max(...planPages);
  const rolloverRatio = Math.floor(rolloverHighest / pagesHighest);
  const overageBlockRange =
    overageBlockLowest === overageBlockHighest
      ? overageBlockLowest
      : `${overageBlockLowest}-${overageBlockHighest}`;

  const printMoreQuestionMessage = getText(
    'plans_selector.plan_modal.print_more_question_message',
    {
      overageBlockRange: overageBlockRange,
      overageBlockPrice: selectedPlan.overageBlockPrice
    }
  );
  const printLessQuestionMessage = getText(
    'plans_selector.plan_modal.print_less_question_message',
    { ratio: rolloverRatio }
  );

  const renderTooltip = () => {
    const windowWidth = window.innerWidth;
    const tooltip = (
      <Tooltip
        arrow
        content={
          <>
            {getText('plans_selector.tooltip_overage_msg', {
              overageBlockSize: selectedPlan.overageBlockSize,
              overageBlockPrice: selectedPlan.overageBlockPrice
            })}
          </>
        }
        id="tooltip"
        placement="bottom"
      >
        <IconInfo customStyle={{ verticalAlign: 'bottom' }} color="hpBlue6" />
      </Tooltip>
    );
    return windowWidth > 500 ? tooltip : <div>{tooltip}</div>;
  };

  return (
    <div>
      <PlanLabelsContainer>
        <div className="info-label" data-testid="print-more">
          <b>{`${getText('plans_selector.plan_modal.print_more')} `}</b>
          <span>{printMoreQuestionMessage}</span>
          {renderTooltip()}
        </div>
        <div className="info-label" data-testid="print-less">
          <b>{`${getText('plans_selector.plan_modal.print_less')} `}</b>
          <span>{printLessQuestionMessage}</span>
        </div>
        {isPriceIncreaseInfo && (
          <PriceUpdateNotice isPlansV1 getText={getText} />
        )}
      </PlanLabelsContainer>
    </div>
  );
};
