import React from 'react';
import {
  CompareYearlyPlanCardContainer,
  CompareYearlyPlanWrapper,
  InfoContainer
} from './styles';
import { PlanName } from '../PlanName';
import { PagesPriceInfo } from '../PagesPriceInfo';
import { PlanTag } from '../PlanTag';
import Button from '@veneer/core/dist/scripts/button';
import { useGetText } from '../../../common/hooks/useGetText';
import { useDispatch } from '../../hooks/useDispatch';
import { setCurrentStepAction } from '../../actions/setCurrentStepAction';
import { ARN_STEP } from '../../../common/constants';
import { useYearlyPlanSelectorState } from '../../hooks/useYearlyPlanSelectorState';
import { setSelectedYearlyPlanAction } from '../../actions/setSelectedYearlyPlanAction';

interface PlanCardProps {
  planName: string;
  pagesTotal: number;
  pricesPage: string;
  yearlyPrice?: string;
  isYearly?: boolean;
  circlesNumber?: number;
}

export const PlanCard: React.FC<PlanCardProps> = ({
  planName,
  pagesTotal,
  pricesPage,
  yearlyPrice = '',
  isYearly = false,
  circlesNumber = 0
}) => {
  const getText = useGetText();
  const dispatch = useDispatch();
  const { onCloseModal, correspondingYearlyPlan } =
    useYearlyPlanSelectorState();

  const buttonText = isYearly
    ? getText('plans_selector.yearly.modals.yearly_button')
    : getText('plans_selector.yearly.modals.monthly_button');
  const tagText = isYearly
    ? getText('plans_selector.common.plan_yearly')
    : getText('plans_selector.common.current_plan');

  const handleButtonClick = () => {
    if (isYearly) {
      if (dispatch) {
        dispatch(setSelectedYearlyPlanAction(correspondingYearlyPlan));
        dispatch(setCurrentStepAction(ARN_STEP));
      }
    } else {
      onCloseModal();
    }
  };

  return (
    <CompareYearlyPlanWrapper data-testid="yearly-plans-plan-card">
      <CompareYearlyPlanCardContainer
        data-testid={'compare-plan-plan-card-container'}
      >
        <PlanTag
          dataTestId={'compare-plan-plan-tag'}
          tagTitle={tagText}
          isYearly={isYearly}
        />
        <InfoContainer>
          <PlanName
            dataTestId={'compare-plan--plan-name'}
            planName={planName}
            circlesNumber={circlesNumber}
            blueColor={isYearly}
          />
          <PagesPriceInfo
            dataTestId={'pages-price-info'}
            pages={pagesTotal}
            price={pricesPage}
            yearlyPrice={yearlyPrice}
          />
        </InfoContainer>
      </CompareYearlyPlanCardContainer>
      <Button
        style={{ width: '100%', borderRadius: '12px', borderColor: '#0076AD' }}
        data-testid={`choose-plan-${isYearly ? 'yearly' : 'monthly'}`}
        data-linkid="ii_plans"
        data-analytics-id="ii_plans"
        appearance="ghost"
        onClick={() => handleButtonClick()}
      >
        {buttonText}
      </Button>
    </CompareYearlyPlanWrapper>
  );
};
