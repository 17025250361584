import { planSelectorV3Action } from '../types/planSelectorV3Action';

export const setAssetsProviderAction = (
  language: string,
  country: string
): planSelectorV3Action => ({
  type: 'SET_ASSETS_PROVIDER',
  language,
  country
});
