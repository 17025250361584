import styled from 'styled-components';
import { breakpointsHelper } from '../../../common/utils/breakpointsHelper';

export const PlanCardContainer = styled.div`
  && {
    display: flex;
    @media (max-width: ${breakpointsHelper.medium_min_768px}) {
      flex-direction: column;
      width: 100%;
    }
  }
`;

export const Title = styled.h4`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
`;

interface BoldTextProps {
  $isMobile: boolean;
}

export const BoldText = styled.b<BoldTextProps>`
  && {
    display: ${({ $isMobile }) => ($isMobile ? 'inline' : 'block')};
  }
`;
