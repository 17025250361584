import styled, { css } from 'styled-components';

interface ImageProps {
  isDesktop?: boolean;
  isTablet?: boolean;
  isMobile?: boolean;
}

export const ImageContainer = styled.div<ImageProps>`
  && {
    ${({ isDesktop, isTablet, isMobile }) => {
      if (isDesktop || isTablet) {
        return css`
          padding-left: 24px;
        `;
      }
      if (isMobile) {
        return css`
          margin-bottom: 24px;
        `;
      }
    }}
  }
`;

export const InkPaperImage = styled.img<ImageProps>`
  && {
    ${({ isTablet }) => {
      if (isTablet) {
        return css`
          margin-top: 25px;
        `;
      }
    }}
  }
`;

export const InkImage = styled.img<ImageProps>`
  && {
    ${({ isDesktop, isTablet }) => {
      if (isDesktop) {
        return css`
          padding: 13px 33px 53px 33px;
        `;
      }
      if (isTablet) {
        return css`
          margin-top: 35px;
        `;
      }
    }}
  }
`;

export const TonerImage = styled.img<ImageProps>`
  && {
    ${({ isDesktop, isTablet }) => {
      if (isDesktop) {
        return css`
          padding-top: 20px;
        `;
      } else if (isTablet) {
        return css`
          position: absolute;
          right: 0px;
          padding-top: 28px;
        `;
      }
    }}
  }
`;

// Since the design asks the toner image to bleed off the screen we are
// positioning it as absolute and inserting this invisible div
// to prevent it from overlapping the text
export const Spacer = styled.div`
  && {
    float: left;
    width: 236px;
  }
`;
