import styled from 'styled-components';
import primitives from '@veneer/primitives';
import IconCircle from '@veneer/core/dist/scripts/icons/icon_circle';
import { getColor } from '../../../common/utils/colorSelector';

type IsYearlyProps = {
  isYearly: boolean;
};

export const TitleContainer = styled.div<IsYearlyProps>`
  &&& {
    h1 {
      font-size: 16px;
      font-weight: 400;
      color: ${primitives.color.gray12};
      line-height: 20px;
      font-family: ${primitives.typography.family0};
      word-break: break-all;
      ${(props) => (props.isYearly ? 'margin-top: 8px;' : 'margin: 0;')}
    }
  }
`;

export const CardTitleContainer = styled.div<IsYearlyProps>`
  && {
    height: ${(props) => (props.isYearly ? `48px` : '40px')};
  }
`;

type CircleProps = {
  program: string;
  $isZeroPlan: boolean;
};

export const Circle = styled(IconCircle)<CircleProps>`
  && {
    margin-right: 6px;
    color: ${(props) =>
      props.$isZeroPlan ? 'transparent' : getColor(props.program)};
  }
`;
