import { createContext, Dispatch } from 'react';
import { YearlyPlanSelectorState } from '../types/yearlyPlanSelectorState';
import { YearlyPlanSelectorAction } from '../types/yearlyPlanSelectorAction';

type YearlyPlanSelectorContextType = {
  yearlyPlanSelectorState?: YearlyPlanSelectorState;
  dispatch?: Dispatch<YearlyPlanSelectorAction>;
};
export const YearlyPlanSelectorContext =
  createContext<YearlyPlanSelectorContextType>({});
