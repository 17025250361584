import React from 'react';
import Button from '@veneer/core/dist/scripts/button';
import {
  GenericErrorHandlerContainer,
  ContentSection,
  ButtonSection,
  ErrorTitle
} from './styles';
import WarningIcon from '@veneer/core/dist/scripts/icons/icon_minus_circle';
import { usePlanSelectorState, useDispatch, useGetText } from '../../hooks';
import { fetchPlansAction } from '../../actions';

export const GenericErrorHandler = () => {
  const getText = useGetText();
  const { plansError, onCancel } = usePlanSelectorState();
  const dispatch = useDispatch();

  if (!plansError) {
    return null;
  }

  return (
    <GenericErrorHandlerContainer>
      <ContentSection>
        <WarningIcon size={48} color={'red6'} />
        <ErrorTitle>{getText('plans_selector.generic_error')}</ErrorTitle>
        {getText('plans_selector.try_again')}
      </ContentSection>
      <ButtonSection>
        <Button
          data-analytics-id="ClosePlansButton"
          appearance="secondary"
          data-testid="cancel-button"
          onClick={(e) => {
            e.target['screenName'] = 'ErrorPage';
            onCancel();
          }}
        >
          {getText('plans_selector.cancel')}
        </Button>
        <Button
          data-analytics-id="TryAgain"
          appearance="primary"
          data-testid="retry-button"
          onClick={(e) => {
            if (plansError) {
              e.target['screenName'] = 'ErrorPage';
              dispatch(fetchPlansAction());
            }
          }}
        >
          {getText('plans_selector.try_again')}
        </Button>
      </ButtonSection>
    </GenericErrorHandlerContainer>
  );
};
