import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const PlanWarningsContainer = styled.div`
  text-align: center;
  display: block;
  vertical-align: baseline;
  color: ${primitives.color.gray8};
  font-size: 100%;
  background: transparent;
`;
