import React from 'react';
import { TagContainer, TagTitle } from './styles';

interface PlanTagProps {
  tagTitle: string;
  color?: string;
  dataTestId?: string;
  isYearly?: boolean;
}
export const PlanTag: React.FC<PlanTagProps> = ({
  tagTitle,
  dataTestId,
  color = '',
  isYearly = false
}) => {
  return (
    <TagContainer
      data-testid={`${dataTestId}-plan-tag-container`}
      isYearly={isYearly}
      color={color}
    >
      <TagTitle data-testid={`${dataTestId}-plan-tag-title`}>
        {tagTitle.toUpperCase()}
      </TagTitle>
    </TagContainer>
  );
};
