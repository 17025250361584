import styled from 'styled-components';
import { PLAN_PROGRAMMES } from '../../../../common/constants/index';
import { breakpointsHelper } from '../../../../common/utils/breakpointsHelper';

interface ISelectPlanButtonContainer {
  program: string;
  savePlanError: unknown;
}

export const SelectPlanContainer = styled.div<ISelectPlanButtonContainer>`
  display: flex;
  max-width: 991px;
  width: 100%;
  justify-content: ${(props) => {
    if (
      props.program === PLAN_PROGRAMMES.SMB ||
      props.program === PLAN_PROGRAMMES.SMB_MONO
    ) {
      return 'space-between';
    } else {
      return 'flex-end';
    }
  }};
  margin-top: ${(props) => (props.savePlanError ? '8px' : '32px')};

  @media (max-width: ${breakpointsHelper.small_max_767px}) {
    flex-direction: column-reverse;
    button:last-child {
      margin-bottom: 16px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
