import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { breakpointsHelper } from '../../../common/utils/breakpointsHelper';

export const Container = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px 48px;

    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      padding: 40px 24px;
    }
  }
`;

export const Title = styled.h1`
  && {
    font-size: 32px;
    font-family: ${primitives.typography.family0};
    color: ${primitives.color.gray12};
    font-weight: 400;
    line-height: 40px;

    @media (max-width: 575px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const Subtitle = styled.h2`
  && {
    font-size: 18px;
    font-family: ${primitives.typography.family0};
    color: ${primitives.color.gray12};
    font-weight: 400;
    line-height: 24px;
    margin-top: 4px;

    @media (max-width: 575px) {
      margin-top: 8px;
    }
  }
`;

export const DividingLine = styled.div`
  && {
    width: 100%;
    height: 1px;
    border: 1px;
    background-color: #d9d9d9;
    margin-top: 24px;
  }
`;

export const HeaderImage = styled.img`
  && {
    border-radius: 15px 15px 0px 0px;
    width: 648px;
  }
`;

export const PaymentLink = styled.a`
  && {
    color: ${primitives.color.hpBlue7};
    cursor: pointer;
  }
`;
