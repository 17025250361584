import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Container, PlanProgramSelectorContainer, Tabs } from './styles';
import { PLAN_PROGRAMMES } from '../../../../../common/constants';
import { useGetText, usePlanSelectorState } from '../../../hooks';
import { PlansSlider } from '../../PlansSlider';

export const PlanProgramSelector = (props) => {
  const getText = useGetText();
  const { plans, program } = usePlanSelectorState();

  const renderPlansContent = () => {
    if (props.shouldShowTabs) {
      const i_ink_plans = plans.filter(
        (plan) => plan.program === PLAN_PROGRAMMES.INSTANT_INK
      );

      const i_toner_plans = plans.filter(
        (plan) => plan.program === PLAN_PROGRAMMES.INSTANT_TONER
      );

      return (
        <Tabs
          controlId="default"
          mode="contained"
          onChangeTab={props.handleChangeProgram}
          selectedTabId={program}
          data-linkid="ii_plan_selector"
          tabs={[
            {
              id: PLAN_PROGRAMMES.INSTANT_INK,
              content: <PlansSlider filteredPlans={i_ink_plans} />,
              label: getText('plans_selector.ink')
            },
            {
              id: PLAN_PROGRAMMES.INSTANT_TONER,
              content: <PlansSlider filteredPlans={i_toner_plans} />,
              label: getText('plans_selector.toner')
            }
          ]}
          type="online"
        />
      );
    }

    const filteredPlans = plans.filter((plan) => plan.program === program);

    return <PlansSlider filteredPlans={filteredPlans} />;
  };

  return (
    <Container>
      <PlanProgramSelectorContainer data-testid="program-selector-tabs">
        {renderPlansContent()}
      </PlanProgramSelectorContainer>
    </Container>
  );
};
