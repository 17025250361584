import styled from 'styled-components';
import ChevronLeft from '@veneer/core/dist/scripts/icons/icon_chevron_left';
import ChevronRight from '@veneer/core/dist/scripts/icons/icon_chevron_right';

type ArrowsContainerProps = {
  direction: string;
};

export const StyledArrowContainer = styled.div<ArrowsContainerProps>`
  && {
    display: flex;
    width: 90px;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-left: ${(props) => (props.direction === 'left' ? 0 : -60)}px;
    margin-right: ${(props) => (props.direction === 'right' ? 0 : -60)}px;
    background-image: ${(props) =>
      props.direction === 'right'
        ? 'linear-gradient(to right, rgba(255, 0, 0, 0), rgba(255, 255, 255, 1))'
        : 'linear-gradient(to left, rgba(255, 0, 0, 0), rgba(255, 255, 255, 1))'};
    z-index: 1;
  }
`;

export const StyledArrow = styled.button`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: black;
    height: 44px;
    width: 44px;
    border: none;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    z-index: 1;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    padding: 0;

    &:hover {
      background-color: #545454;
    }
  }
`;

export const IconLeft = styled(ChevronLeft)`
  &&& {
    color: #ffffff;
    margin: 0;
  }
`;

export const IconRight = styled(ChevronRight)`
  &&& {
    color: #ffffff;
    margin: 0;
  }
`;
