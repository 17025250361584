import styled from 'styled-components';
import { PLAN_PROGRAMMES } from '../../../common/constants';

export const PlansCard = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 247px;
    min-width: 247px;
    min-height: 212px;
    border-radius: 8px;
    border: 1px solid #dbdbdb;
    margin: 0px 8px;
    background-color: #ffffff;

    @media (min-width: 576px) and (max-width: 1399px) {
      width: 210px;
      min-width: 210px;
    }

    @media (max-width: 575px) {
      width: 327px;
      min-width: 327px;
      margin: 8px 0px;
    }
  }
`;

export const InfoContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    padding: 24px 16px 0px 16px;
    height: 100%;
  }
`;

type ColorContainerProps = {
  program: string;
};
export const ColorContainer = styled.div<ColorContainerProps>`
  && {
    width: 100%;
    height: 16px;
    border-radius: 0px 0px 7px 7px;
    background-color: ${(props) => {
      switch (props.program) {
        case PLAN_PROGRAMMES.INSTANT_INK:
          return '#B655F5';
        case PLAN_PROGRAMMES.INSTANT_TONER:
          return '#4759F5';
        case PLAN_PROGRAMMES.SMB:
          return '#0096D6';
        case PLAN_PROGRAMMES.SMB_MONO:
          return '#231F20';
        default:
          return '#B655F5';
      }
    }};
  }
`;
