import { createContext, Dispatch } from 'react';
import { LandingPagePlansState } from '../types/landingPagePlansState';
import { LandingPagePlansAction } from '../types/landingPagePlansAction';

type LandingPagePlansContextType = {
  landingPagePlansState?: LandingPagePlansState;
  dispatch?: Dispatch<LandingPagePlansAction>;
};
export const LandingPagePlansContext =
  createContext<LandingPagePlansContextType>({});
