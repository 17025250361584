import styled from 'styled-components';
import { getColor } from '../../../common/utils/colorSelector';
import primitives from '@veneer/primitives';

type PlanCardContainerProps = {
  isSelected: boolean;
  disableClick: boolean;
};

export const PlanCardContainer = styled.div<PlanCardContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 340px;
  border-radius: 8px;
  border: ${(props) =>
    props.isSelected ? '1px solid #212121' : '1px solid #dbdbdb'};
  background-color: #ffffff;
  cursor: ${(props) => (props.disableClick ? 'none' : 'pointer')};
  pointer-events: ${(props) => (props.disableClick ? 'none' : 'auto')};

  &:hover button {
    background-color: rgba(0, 118, 173, 0.05);
    border-color: ${primitives.color.hpBlue8};
    color: ${primitives.color.hpBlue8};
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-right: 0;
    justify-self: center;
  }
`;

export const InfoContainer = styled.div`
  && {
    pointer-events: none;
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0px 16px;
  }
`;

export const TransparentTag = styled.div`
  && {
    pointer-events: none;
    width: 100%;
    height: 24px;
    background-color: transparent;
  }
`;

export const HorizontalLine = styled.div`
  && {
    width: 100%;
    height: 1px;
    background-color: #dbdbdb;
    margin-top: 24px;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: auto;
  padding: 16px 16px 0px 16px;
  pointer-events: none;

  button {
    width: 100%;
    height: 42px;
    border-radius: 8px;

    span {
      white-space: normal;
    }
  }
`;

type ColorContainerProps = {
  program: string;
};

export const ColorContainer = styled.div<ColorContainerProps>`
  && {
    pointer-events: none;
    width: 100%;
    height: 16px;
    border-radius: 0px 0px 7px 7px;
    background-color: ${(props) => getColor(props.program)};
  }
`;
