import AssetsProviderFactory from '../../../assets/AssetsProviderFactory';
import { PlanSelectorState } from '../../../common/types/planSelectorState';
import {
  PlanSelectorAction,
  PlanSelectorActionType
} from '../../../common/types/planSelectorAction';
import { PlanSelectorProps } from '../components/PlanSelector';

export const planSelectorInitialState = ({
  getPlans,
  savedPlanGUID = '',
  country,
  language,
  defaultPlanGUIDInk = '',
  defaultPlanGUIDToner = '',
  onPlanChange,
  onCancel,
  trackClickEvent,
  program,
  showPlanProgramTabs,
  showPriceIncreaseInfo
}: PlanSelectorProps): PlanSelectorState => ({
  getPlans,
  assetsProvider: AssetsProviderFactory.create(language, country),
  savedPlanGUID,
  country,
  language,
  defaultPlanGUIDInk,
  defaultPlanGUIDToner,
  onPlanChange,
  onCancel,
  trackClickEvent,
  program,
  showPlanProgramTabs,
  selectedPlanGUID: savedPlanGUID || defaultPlanGUIDInk || defaultPlanGUIDToner,
  showPriceIncreaseInfo
});

export const planSelectorReducer = (
  state: PlanSelectorState,
  action: PlanSelectorAction
) => {
  const {
    FETCH_PLANS,
    FETCH_PLANS_SUCCESS,
    FETCH_PLANS_FAIL,
    SET_ASSETS_PROVIDER,
    SET_CONTAINTER_SIZE,
    SET_SELECTED_PLAN_GUID,
    SAVE_PLAN,
    SAVE_PLAN_SUCCESS,
    SAVE_PLAN_FAIL,
    CLEAR_SAVE_PLAN_ERROR,
    SET_DEFAULT_PLAN_GUID_TONER,
    SET_DEFAULT_PLAN_GUID_INK,
    SET_SELECTED_PLAN,
    SET_SELECTED_PROGRAM
  } = PlanSelectorActionType;

  let newState = state;

  switch (action.type) {
    case FETCH_PLANS:
      newState = {
        ...state,
        plansLoading: true,
        plansError: undefined
      };
      break;
    case FETCH_PLANS_SUCCESS:
      newState = {
        ...state,
        plansLoading: false,
        plans: action.plans
      };
      break;
    case FETCH_PLANS_FAIL:
      newState = {
        ...state,
        plansLoading: false,
        plansError: action.error
      };
      break;
    case SET_ASSETS_PROVIDER:
      newState = {
        ...state,
        assetsProvider: AssetsProviderFactory.create(
          action.language,
          action.country
        )
      };
      break;
    case SET_CONTAINTER_SIZE:
      newState = {
        ...state,
        containerSize: action.containerSize
      };
      break;
    case SET_SELECTED_PLAN_GUID:
      newState = {
        ...state,
        selectedPlanGUID: action.selectedPlanGUID
      };
      break;
    case SAVE_PLAN:
      newState = {
        ...state,
        isSavingPlan: true,
        savePlanError: undefined
      };
      break;
    case SAVE_PLAN_SUCCESS:
      newState = {
        ...state,
        isSavingPlan: false,
        savedPlanGUID: action.savedPlanGUID
      };
      break;
    case SAVE_PLAN_FAIL:
      newState = {
        ...state,
        isSavingPlan: false,
        savePlanError: action.error
      };
      break;
    case CLEAR_SAVE_PLAN_ERROR:
      newState = {
        ...state,
        savePlanError: undefined
      };
      break;
    case SET_SELECTED_PLAN:
      newState = {
        ...state,
        selectedPlanGUID: action.selectedPlan?.guid,
        selectedPlan: action.selectedPlan
      };
      break;
    case SET_DEFAULT_PLAN_GUID_INK:
      newState = {
        ...state,
        defaultPlanGUIDInk: action.defaultPlanGUIDInk
      };
      break;
    case SET_DEFAULT_PLAN_GUID_TONER:
      newState = {
        ...state,
        defaultPlanGUIDToner: action.defaultPlanGUIDToner
      };
      break;
    case SET_SELECTED_PROGRAM:
      newState = {
        ...state,
        program: action.program
      };
      break;
  }

  return newState;
};
