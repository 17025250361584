import React from 'react';
import { Plan } from '../../../../common/types/plan';
import {
  StyledMobilePlanCard,
  StyledPlanCard,
  StyledPlanCardSMB,
  StyledMobilePlanCardSmb
} from './styles';
import RadioButton from '@veneer/core/dist/scripts/radio_button/radio_button';
import {
  usePlanSelectorState,
  useDispatch,
  useWindowSize,
  useGetText
} from '../../hooks';
import { clearSavePlanError, setSelectedPlanAction } from '../../actions';
import {
  ANALYTICS_EVENT_LABEL,
  PLAN_PROGRAMMES,
  TABLET_LANDSCAPE_MAX
} from '../../../../common/constants';
import { PlanBadge } from './planBadge';
import { isSMBPlanProgram } from '../../../../common/lib/smbHelper';
import { trackClickEvent } from '../../../../common/types/planSelectorState';

const getPlanCardClass = (program, isRecommended, isPopular) => {
  switch (program) {
    case PLAN_PROGRAMMES.SMB_MONO:
    case PLAN_PROGRAMMES.SMB:
    case PLAN_PROGRAMMES.INSTANT_TONER:
      return isRecommended
        ? 'smb-recommended-plan-card'
        : 'smb-regular-plan-card';
    case PLAN_PROGRAMMES.INSTANT_INK:
      return isPopular ? 'smb-popular-plan-card' : 'smb-regular-plan-card';
    default:
      return '';
  }
};

export const getDesktopPlanCardByProgram = (program) => {
  switch (program) {
    case PLAN_PROGRAMMES.SMB_MONO:
    case PLAN_PROGRAMMES.SMB:
    case PLAN_PROGRAMMES.INSTANT_INK:
    case PLAN_PROGRAMMES.INSTANT_TONER:
      return StyledPlanCardSMB;
    default:
      return StyledPlanCard;
  }
};

export const getMobilePlanCardByProgram = (program: string) => {
  switch (program) {
    case PLAN_PROGRAMMES.SMB_MONO:
    case PLAN_PROGRAMMES.SMB:
    case PLAN_PROGRAMMES.INSTANT_INK:
    case PLAN_PROGRAMMES.INSTANT_TONER:
      return StyledMobilePlanCardSmb;
    default:
      return StyledMobilePlanCard;
  }
};

interface IPlanCardProps {
  plan?: Plan;
  country?: string;
  trackClickEvent?: trackClickEvent;
}

const PlanCard: React.FC<IPlanCardProps> = ({ plan, country }) => {
  const {
    plans,
    selectedPlanGUID,
    defaultPlanGUIDInk,
    defaultPlanGUIDToner,
    program,
    savePlanError,
    trackClickEvent
  } = usePlanSelectorState();
  const dispatch = useDispatch();
  const getText = useGetText();
  const filteredProgramPlans = plans.filter((p) => p.program === plan.program);
  const order = filteredProgramPlans.indexOf(plan);
  const resolution = useWindowSize();
  const isDesktop = resolution >= TABLET_LANDSCAPE_MAX;
  const isTonerProgram =
    isSMBPlanProgram(program) || program === PLAN_PROGRAMMES.INSTANT_TONER;
  const isRecommended = isTonerProgram && defaultPlanGUIDToner === plan.guid;
  const isPopular =
    program === PLAN_PROGRAMMES.INSTANT_INK && defaultPlanGUIDInk === plan.guid;
  const planBadge = PlanBadge({
    isPopular,
    isDesktop,
    program,
    isRecommended
  });

  const [wrapperWidth, setWrapperWidth] = React.useState(0);

  React.useEffect(() => {
    const sliderCard = document.getElementById('sliderCard');
    const sliderWidth = sliderCard?.getBoundingClientRect().width;
    setWrapperWidth(sliderWidth);
  }, [resolution]);

  const isDualPrice = country === 'HR';

  const dualPrice = (price) => {
    const priceList = price.split(' ');
    const firstPrice = priceList.slice(0, 2).join(' ');
    const secondPrice = priceList.slice(2).join(' ');

    return { firstPrice: firstPrice, secondPrice: secondPrice };
  };

  const updatePlanSelection = (plan: Plan) => {
    dispatch(setSelectedPlanAction(plan));
    if (savePlanError) dispatch(clearSavePlanError());
  };

  const getRadioButton = () => (
    <RadioButton
      data-testid={`PlanRadioButton-${plan.program}-${plan.pages}`}
      className={`plans-radiobtn-${plan.program}-${plan.frequency}`}
      data-linkid={ANALYTICS_EVENT_LABEL}
      aria-label={`${plan.pages}-page-plan`}
      id={plan.guid}
      value={plan.guid}
      checked={plan.guid === selectedPlanGUID}
      onChange={() => {
        trackClickEvent(
          ANALYTICS_EVENT_LABEL,
          `${plan.program.replace('i_', '')}-plan-${plan.frequency}`
        );
        updatePlanSelection(plan);
      }}
    />
  );

  const getContent = () => {
    return !isDesktop ? (
      <div className="card-item" data-testid="card-item">
        <div className="left-container">
          <div className="plan-name">
            {getText(`plans_selector.plan_card.frequency.${plan.frequency}`)}
          </div>
          <div
            className={`plan-pages plans-Page-${plan.program}-${plan.frequency}`}
            data-testid={`Page-${plan.guid}`}
          >
            {`${plan.pages} ${getText(
              'plans_selector.plan_card.pages_per_month'
            )}`}
          </div>
        </div>

        <div className="right-container">
          {planBadge && <div className="plan-badge">{planBadge}</div>}
          <div className="plan-price-selector">
            <div
              className={`price plans-price-${plan.program}-${plan.frequency}`}
              data-testid={`price-${plan.guid}`}
            >
              <p
                data-testid={`Price-${plan.guid}`}
                className={`plans-Price-${plan.program}-${plan.frequency}`}
              >
                {isDualPrice
                  ? `${dualPrice(plan.price).firstPrice} `
                  : plan.price}

                {isDualPrice ? `${dualPrice(plan.price).secondPrice}` : ''}

                {getText('plans_selector.plan_card.per_month')}
              </p>
            </div>
            <div
              style={{ WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)' }}
              onClick={() => updatePlanSelection(plan)}
            >
              {getRadioButton()}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <>
        {planBadge}
        <div className="plan-card-items">
          <div
            className={`plan-flavor-section plans-Icon-${plan.program}-${plan.frequency}`}
            data-testid={`Icon-${plan.guid}`}
          >
            <>
              <p>
                {getText(
                  `plans_selector.plan_card.frequency.${plan.frequency}`
                )}
              </p>
              <p
                className={`plans-Price-${plan.program}-${plan.frequency}`}
                data-testid={`Price-${plan.guid}`}
              >
                {isDualPrice
                  ? `${dualPrice(plan.price).firstPrice} `
                  : plan.price}
                {isDualPrice ? `${dualPrice(plan.price).secondPrice}` : ''}
                {isDualPrice && <br></br>}
                {getText('plans_selector.plan_card.per_month')}
              </p>
            </>
          </div>
          <div className="price-pages-container">
            <div data-testid={'pages-per-month'}>
              <p>
                <b
                  data-testid={`Page-${plan.guid}`}
                  className={`subtitle-large plans-Page-${plan.program}-${plan.frequency}`}
                >
                  {plan.pages}
                </b>
              </p>
              <p>{getText('plans_selector.plan_card.pages_per_month')}</p>
            </div>
          </div>
          <div
            className="radio-container"
            onClick={() => updatePlanSelection(plan)}
          >
            {getRadioButton()}
          </div>
        </div>
        <div className="smb-card-bottom" />
      </>
    );
  };

  const StyledCard = isDesktop
    ? getDesktopPlanCardByProgram(program)
    : getMobilePlanCardByProgram(program);
  return (
    <StyledCard
      className={`${getPlanCardClass(
        program,
        isRecommended,
        isPopular
      )} plans-plan-card-${plan.program}-${plan.frequency}`}
      order={order}
      program={program}
      totalcards={filteredProgramPlans.length}
      appearance="dropShadow"
      cardwidth={wrapperWidth}
      content={getContent()}
      data-testid={`plan-card-${plan.guid}`}
      data-linkid="ii_plan_selector"
    />
  );
};

export default PlanCard;
