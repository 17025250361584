import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const PlanLabelsContainer = styled.div`
  && {
    -webkit-text-size-adjust: 100%;
    font-family: ${primitives.typography.family0};
    text-align: center;
    display: block;
    div.info-label {
      line-height: 20px;
      color: ${primitives.color.gray8};
      background: transparent;
      border: 0;
      margin: 0;
      outline: 0;
      padding: 0;
      vertical-align: baseline;

      #tooltip {
        font-size: 14px;
        line-height: 20px;
      }

      svg {
        margin-left: 5px;
      }

      b {
        font-family: ${primitives.typography.family0};
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }

      span {
        font-family: ${primitives.typography.family0};
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
`;
