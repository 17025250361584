import styled from 'styled-components';

export const SliderCard = styled.div`
  && {
    display: flex;
    width: 100%;
    outline: none;
    &:focus {
      outline: none;
    }
  }
`;

export const Container = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    div::-webkit-scrollbar {
      display: none;
    }

    div {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
`;

export const FlexContainer = styled.div`
  && {
    display: flex;
  }
`;

export type SliderContainerProps = {
  isDesktop?: boolean;
};

export const StyledSlider = styled.div`
  && {
    width: 100%;
    max-width: 936px;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
`;
