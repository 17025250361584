import primitives from '@veneer/primitives';
import styled from 'styled-components';

export const Container = styled.div`
  && {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }
`;

export const PriceContent = styled.span`
  && {
    margin-left: 8px;
    position: relative;
    text-decoration: none;
  }
  &&::after {
    content: '';
    position: absolute;
    top: 48%;
    left: 0;
    width: 100%;
    height: 2px;
    background: ${primitives.color.cornFlowerBlue7};
    transform: rotate(9.82deg);
  }
`;

export const PagesContainer = styled.div`
  &&& {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 8px;
    align-items: center;

    h1 {
      font-size: 28px;
      font-family: ${primitives.typography.family0};
      color: ${primitives.color.gray12};
      font-weight: 400;
      line-height: 36px;
      margin: 0px;
      word-break: break-all;
    }

    h2 {
      font-size: 18px;
      font-family: ${primitives.typography.family0};
      color: ${primitives.color.gray12};
      font-weight: 400;
      line-height: 24px;
      margin: 0px 0px 0px 4px;
      word-break: break-all;
    }
  }
`;

export const PriceContainer = styled.div`
  &&& {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;

    h1 {
      font-size: 18px;
      font-family: ${primitives.typography.family0};
      color: ${primitives.color.gray9};
      font-weight: 400;
      line-height: 24px;
      margin: 0px;
      word-break: break-all;
    }

    h2 {
      font-size: 14px;
      font-family: ${primitives.typography.family0};
      color: ${primitives.color.gray9};
      font-weight: 400;
      line-height: 12px;
      margin: 0px 0px 0px 2px;
      word-break: break-all;
    }
  }
`;
