import React from 'react';
import { useLandingPagePlansState } from '../../hooks/useLandingPagePlansState';
import { useGetText } from '../../hooks/useGetText';
import { Title, Circle } from './styles';
import { NON_BREAKING_SPACE, PLAN_PROGRAMMES } from '../../../common/constants';

interface PlanNameProps {
  planName: string;
  circlesNumber?: number;
  dataTestId?: string;
}

export const PlanName: React.FC<PlanNameProps> = ({
  planName,
  circlesNumber = 0,
  dataTestId
}) => {
  const { program } = useLandingPagePlansState();
  const getText = useGetText();
  const isToner = program === PLAN_PROGRAMMES.INSTANT_TONER;

  return (
    <div>
      {Array.from({ length: circlesNumber }, (_, i) => {
        return <Circle key={i} filled size={15} program={program} />;
      })}
      {planName ? (
        <Title data-testid={`${dataTestId}-plan-name-title`}>
          {isToner
            ? NON_BREAKING_SPACE
            : getText(`plans_selector.common.plans_frequency.${planName}`)}
        </Title>
      ) : (
        <div style={{ height: 30 }} />
      )}
    </div>
  );
};
