import React from 'react';

import { TagContainer, TagTitle } from './styles';

interface PlanTagProps {
  tagTitle: string;
  color?: string;
  dataTestId?: string;
  isSelected?: boolean;
}
export const PlanTag: React.FC<PlanTagProps> = ({
  tagTitle,
  color,
  dataTestId,
  isSelected = false
}) => {
  return (
    <TagContainer
      data-testid={`${dataTestId}-plan-tag-container`}
      color={color}
      isSelected={isSelected}
    >
      <TagTitle data-testid={`${dataTestId}-plan-tag-title`}>
        {tagTitle}
      </TagTitle>
    </TagContainer>
  );
};
