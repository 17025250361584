import primitives from '@veneer/primitives';
import styled from 'styled-components';

export const Container = styled.div`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const PagesContainer = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 4px;
    padding-bottom: 20px;
    align-items: center;
    border-bottom: 1px solid ${primitives.color.gray3};

    h2 {
      font-size: 18px;
      font-family: ${primitives.typography.family0};
      color: ${primitives.color.gray9};
      font-weight: 400;
      line-height: 24px;
      margin: 0px;
      margin: 0px 0px 0px 4px;
      word-break: break-all;
    }
  }
`;

export const PriceContainer = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 20px;

    span {
      font-size: 12px;
      font-family: ${primitives.typography.family0};
      color: ${primitives.color.gray9};
      font-weight: 400;
      line-height: 16px;
    }
    &:last-child {
      margin-bottom: 12px;
    }
  }
`;

export const PriceTitleContainer = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2px;
    margin-bottom: 8px;
    align-items: center;

    h2 {
      font-size: 18px;
      font-family: ${primitives.typography.family0};
      color: ${primitives.color.gray12};
      font-weight: 400;
      line-height: 24px;
      margin: 0px 0px 0px 4px;
    }
  }
`;

export const PagesTitle = styled.h1`
  && {
    font-size: 20px;
    font-family: ${primitives.typography.family0};
    color: ${primitives.color.gray9};
    font-weight: 400;
    line-height: 28px;
    margin: 0px;
  }
`;

export const PriceTitle = styled.h1`
  && {
    font-size: 28px;
    font-family: ${primitives.typography.family0};
    color: ${primitives.color.gray12};
    font-weight: 400;
    line-height: 36px;
    padding: 0px;
  }
`;
