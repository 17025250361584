import React, { useEffect, useMemo, useReducer } from 'react';
import { PlanCards } from './components/PlanCards';
import {
  NoScrollViewContainer,
  PlansContainer,
  ScrollViewContainer
} from './styles';
import { LandingPagePlansContext } from './context/LandingPagePlansContext';
import {
  landingPagesPlansInitialState,
  landingPagePlansReducer
} from './reducers/landingPagePlansReducer';
import { setAssetsProviderAction } from './actions/setAssetsProviderAction';
import { ScrollView } from './components/ScrollView';

interface LandingPagePlansProps {
  plans: [];
  country: string;
  language: string;
  defaultPlanGUIDInk?: string;
  defaultPlanGUIDToner?: string;
  program: string;
}

export const LandingPagePlans: React.FC<LandingPagePlansProps> = (props) => {
  const [landingPagePlansState, dispatch] = useReducer(
    landingPagePlansReducer,
    landingPagesPlansInitialState(props)
  );

  useEffect(() => {
    dispatch(setAssetsProviderAction(props.language, props.country));
  }, [props.language, props.country]);

  const contextValue = useMemo(
    () => ({ landingPagePlansState, dispatch }),
    [landingPagePlansState, dispatch]
  );

  return (
    <LandingPagePlansContext.Provider value={contextValue}>
      <ScrollViewContainer>
        <ScrollView>
          <PlansContainer data-testid="scrollable-landing-page-plans-container">
            <PlanCards />
          </PlansContainer>
        </ScrollView>
      </ScrollViewContainer>
      <NoScrollViewContainer>
        <PlansContainer data-testid="landing-page-plans-container">
          <PlanCards />
        </PlansContainer>
      </NoScrollViewContainer>
    </LandingPagePlansContext.Provider>
  );
};

export default LandingPagePlans;
