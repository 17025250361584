import styled from 'styled-components';
import primitives from '@veneer/primitives';

type ContainerTagProps = {
  color: string;
};
export const TagContainer = styled.div<ContainerTagProps>`
  && {
    display: flex;
    align-items: center;
    width: 100%;
    height: 24px;
    background-color: ${(props) => (props.color ? props.color : '#e6e6e6')};
  }
`;

export const TagTitle = styled.span`
  && {
    font-family: ${primitives.typography.family0};
    color: ${primitives.color.gray12};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 4px 0px 4px 16px;
  }
`;
