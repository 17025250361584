import styled from 'styled-components';

export const ContentContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const InfoContainer = styled.div`
  && {
  }
`;
