import styled from 'styled-components';

export const GenericErrorHandlerContainer = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  width: 95%;
  margin: auto;
  align-items: center;
`;

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 222px;

  svg {
    margin-bottom: 24px;
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  margin: 10px 0px;
  align-content: center;
  justify-content: center;

  button {
    margin: 10px;
    min-width: 0;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const ErrorTitle = styled.div`
  font-size: 32px;
  margin: 12px 0 22px 0;
`;
