import { DashboardPlan } from '../../common/types/dashboardPlan';

export const defaultPlan: DashboardPlan = {
  id: 0,
  guid: '',
  price: '',
  pages: 0,
  overageBlockPrice: '',
  overageBlockSize: 0,
  rollover: 0,
  frequency: '',
  addons: [] as [],
  yearlyPrice: '',
  yearlyPlan: false
};

export const findPlanBySavedPlanGUID = (
  plans: DashboardPlan[],
  savedPlanGUID
) => {
  const index = plans.findIndex((plan) => plan.guid === savedPlanGUID);
  const plan = index !== -1 ? plans[index] : defaultPlan;
  return { plan, index };
};
