import React from 'react';
import { useGetText } from '../../hooks/useGetText';

import {
  Container,
  PagesContainer,
  PriceContainer,
  PagesTitle,
  PriceTitle
} from './styles';

interface PagesPriceInfoProps {
  pages: number;
  price: string;
  dataTestId?: string;
}

export const PagesPriceInfo: React.FC<PagesPriceInfoProps> = ({
  pages,
  price,
  dataTestId
}) => {
  const getText = useGetText();
  return (
    <Container>
      <PagesContainer data-testid={`${dataTestId}-pages-container`}>
        <PagesTitle data-testid={`${dataTestId}-pages-title`}>
          {pages}
        </PagesTitle>
        <h2>{getText('plans_selector.plan_card.pages_per_month')}</h2>
      </PagesContainer>
      <PriceContainer data-testid={`${dataTestId}-price-container`}>
        <PriceTitle data-testid={`${dataTestId}-price-title`}>
          {price}
        </PriceTitle>
        <h2>{getText('plans_selector.plan_card.per_month')}</h2>
      </PriceContainer>
    </Container>
  );
};
