import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { breakpointsHelper } from '../../../common/utils/breakpointsHelper';

export const Table = styled.div`
  && {
    border-radius: 8px;
    border: 1px solid ${primitives.color.gray3};
    font-size: 16px;

    div:nth-child(odd) {
      background-color: ${primitives.color.gray0};
    }

    div:first-child {
      border-radius: 8px 8px 0 0;
    }

    div:last-child {
      border-radius: 0 0 8px 8px;
    }
  }
`;

export const TableRow = styled.div`
  && {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 'title-cell monthly-cell yearly-cell';

    @media (max-width: ${breakpointsHelper.medium_max_991px}) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'title-cell title-cell'
        'divider divider'
        'monthly-cell yearly-cell';
    }
  }
`;

export const TableCell = styled.span`
  && {
    display: flex;
    padding: 16px;
    align-items: center;
    align-self: stretch;
    flex-direction: column;
    justify-self: flex-start;

    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      padding: 16px 6px 16px 16px;
    }
  }
`;

export const TitleCell = styled(TableCell)`
  && {
    grid-area: title-cell;
    font-weight: 700;

    @media (max-width: ${breakpointsHelper.medium_max_991px}) {
      padding-bottom: 2px;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 14px;
    }
  }
`;

export const MonthlyCell = styled(TableCell)`
  && {
    grid-area: monthly-cell;
  }
`;

export const YearlyCell = styled(TableCell)`
  && {
    grid-area: yearly-cell;
  }
`;

export const HeaderContainer = styled.div`
  && {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: ${breakpointsHelper.medium_max_991px}) {
      grid-template-columns: 1fr 1fr;
      div:first-child {
        margin-left: 8px;
      }
    }
  }
`;

interface PlanLabelProps {
  isMonthly?: boolean;
}

export const PlanLabel = styled.div<PlanLabelProps>`
  && {
    display: flex;
    justify-content: center;
    border-radius: 8px 8px 0px 0px;
    background: ${({ isMonthly }) =>
      isMonthly
        ? primitives.color.mediumOrchid4
        : primitives.color.cornFlowerBlue4};
    margin-right: 8px;
  }
`;

export const LabelText = styled.span`
  && {
    padding: 8px 16px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
`;

export const Divider = styled.hr`
  && {
    grid-area: divider;
    margin: 2px 16px 2px 16px;
    height: 0px;
    border: 0;
    border-top: 1px solid ${primitives.color.gray3};
  }
`;
