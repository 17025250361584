import React, { useState } from 'react';

import {
  Container,
  Title,
  InfoContainer,
  ModalInfoContainer,
  BottomContainer,
  IconContainer,
  UpdatePlanAndShippingContainer,
  ModalInfoAndButtonContainer,
  Button,
  IconInfoHPX
} from './styles';
import IconTruck from '@veneer/core/dist/scripts/icons/icon_truck';
import IconInfo from '@veneer/core/dist/scripts/icons/icon_info';
import IconArrowCorners from '@veneer/core/dist/scripts/icons/icon_arrow_corners';
import { PlanTabs } from '../../../common/components/PlanTabs';
import { IndividualPlanInfo } from '../IndividualPlanInfo';
import { useConsumerPlansState } from '../../../hooks/useConsumerPlansState';
import { PlanCards } from '../../../common/components/PlanCards';
import { useGetText } from '../../../hooks/useGetText';
import { PriceUpdateNotice } from '../../../../common/components/PriceUpdateNotice';
import { useWindowSize } from '../../../../../common/hooks/useWindowSize';
import { MIN_TABLET_RESOLUTION } from '../IndividualPlanInfo/common/helpers/resolutionHelper';
import { useDispatch } from '../../../hooks/useDispatch';
import iconInfoHPX from '../../../../../../imgs/Info.svg';
import { ANALYTICS_EVENT_LABEL } from '../../../../../../src/common/constants';

export const ConsumerPlanContent: React.FC = () => {
  const getText = useGetText();
  const [showIndividualPlanInfo, setShowIndividualPlanInfo] = useState(false);
  const [disableSelectPlanButton, setDisableSelectPlanButton] = useState(false);
  const [showLearnMore, setShowLearnMore] = useState(false);
  const {
    plans,
    selectedPlan,
    onPlanChange,
    program,
    showPriceIncreaseInfo,
    showPlanProgramTabs,
    renderLearnMore,
    isSavingPlan,
    isHPX,
    trackClickEvent
  } = useConsumerPlansState();
  const dispatch = useDispatch();
  const showPriceIncrease =
    (program === 'i_ink_paper' || program === 'i_ink') && showPriceIncreaseInfo;

  const resolution = useWindowSize();
  const isTablet = resolution <= 970 && resolution >= MIN_TABLET_RESOLUTION;
  const isMobile = resolution < MIN_TABLET_RESOLUTION;

  const hasInkPaper = !!plans?.i_ink_paper?.length;
  const PlanLabels = {
    i_ink_paper: getText('plans_selector.tabs.ink_paper'),
    i_ink: hasInkPaper
      ? getText('plans_selector.tabs.only_ink')
      : getText('plans_selector.ink'),
    i_toner: getText('plans_selector.toner')
  };

  const createTabsContent = () => {
    const plan = [];
    Object.entries(plans).forEach(([key, plans]) => {
      if (plans.length > 0) {
        plan.push({
          id: key,
          content: (
            <PlanCards
              plans={plans}
              isInkPaper={key === 'i_ink_paper'}
              disableSelectPlanButton={setDisableSelectPlanButton}
              setShowLearnMore={setShowLearnMore}
            />
          ),
          label: PlanLabels[key]
        });
      }
    });
    return plan;
  };

  const tabs = createTabsContent();

  const savePlanChange = async () => {
    if (trackClickEvent) {
      trackClickEvent(ANALYTICS_EVENT_LABEL, 'consumer-plans-select-plans');
    }

    if (dispatch) {
      try {
        dispatch({ type: 'SAVE_PLAN' });
        await onPlanChange(selectedPlan.id, selectedPlan.isInkPaperPlan);
        dispatch({ type: 'SAVE_PLAN_SUCCESS' });
      } catch (error) {
        console.log(error);
        dispatch({ type: 'SAVE_PLAN_FAIL' });
      }
    }
  };

  const onOpenIndividualPlanInfo = () => {
    if (trackClickEvent) {
      trackClickEvent(
        ANALYTICS_EVENT_LABEL,
        'plans-consumer-plan-content-info-icon'
      );
    }
    if (disableSelectPlanButton) {
      return null;
    }
    setShowIndividualPlanInfo(true);
  };

  if (showIndividualPlanInfo) {
    return (
      <IndividualPlanInfo
        setShowIndividualPlanInfo={setShowIndividualPlanInfo}
      />
    );
  }

  if (showLearnMore) {
    return renderLearnMore(() => setShowLearnMore(false));
  }

  return (
    <Container data-testid={'plans-consumer-plan-content-container'}>
      <Title isMobile={isMobile}>
        {getText('plans_selector.select_plan_title_smb')}
      </Title>
      {showPlanProgramTabs ? (
        <PlanTabs hasInkPaper={hasInkPaper} tabsContent={tabs} />
      ) : (
        <PlanCards
          plans={plans[program]}
          isInkPaper={program === 'i_ink_paper'}
          disableSelectPlanButton={setDisableSelectPlanButton}
          setShowLearnMore={setShowLearnMore}
        />
      )}

      <BottomContainer>
        <UpdatePlanAndShippingContainer>
          <InfoContainer>
            <IconContainer size={32}>
              <IconArrowCorners size={32} color="gray9" />
            </IconContainer>
            <span>{getText('plans_selector.change_pause_cancel')}</span>
          </InfoContainer>
          <InfoContainer>
            <IconContainer size={32}>
              <IconTruck size={32} color="gray9" />
            </IconContainer>

            <span>{getText('plans_selector.shipping_and_cartridge')}</span>
          </InfoContainer>
        </UpdatePlanAndShippingContainer>
        <ModalInfoAndButtonContainer>
          <ModalInfoContainer disabledIcon={disableSelectPlanButton}>
            <span>
              {getText('plans_selector.what_happens_when')}{' '}
              {isHPX ? (
                <IconInfoHPX
                  src={iconInfoHPX}
                  alt="Photo of Hp toner cartridges"
                  data-testid="hpx-info-icon"
                  disableClick={disableSelectPlanButton}
                  onClick={onOpenIndividualPlanInfo}
                />
              ) : (
                <IconInfo
                  data-testid={'plans-consumer-plan-content-info-icon'}
                  color={isHPX ? '#8283981F' : 'hpBlue7'}
                  filled={isHPX}
                  size={20}
                  disabled={disableSelectPlanButton}
                  onClick={onOpenIndividualPlanInfo}
                />
              )}
            </span>

            {showPriceIncrease && <PriceUpdateNotice getText={getText} />}
          </ModalInfoContainer>
          <Button
            $isTablet={isTablet}
            $isMobile={isMobile}
            disabled={disableSelectPlanButton}
            loading={isSavingPlan}
            appearance="secondary"
            data-testid="consumer-plans-select-plans"
            onClick={() => savePlanChange()}
          >
            {getText('plans_selector.select_plan')}
          </Button>
        </ModalInfoAndButtonContainer>
      </BottomContainer>
    </Container>
  );
};
