import styled, { css } from 'styled-components';
import { breakpointsHelper } from '../../../common/utils/breakpointsHelper';
import Modal from '@veneer/core/dist/scripts/modal';
import {
  ARN_STEP,
  BILLING_FORM_STEP,
  COMPARE_YEARLY_MONTHLY_STEP,
  YEARLY_PLAN_SELECTOR_STEP
} from '../../../common/constants';

interface YearlyModalProps {
  $currentStep: string;
}

export const YearlyModal = styled(Modal)<YearlyModalProps>`
  ${({ $currentStep }) => {
    if ($currentStep === COMPARE_YEARLY_MONTHLY_STEP) {
      return css`
        .vn-modal--content {
          max-width: 920px;
          padding: 48px 40px 40px 40px;

          @media (max-width: ${breakpointsHelper.extra_small_575px}) {
            padding: 40px 16px 40px 16px;
          }
        }
      `;
    } else if ($currentStep === YEARLY_PLAN_SELECTOR_STEP) {
      return css`
        .vn-modal--content {
          max-width: 920px;
          padding: 48px 40px 40px 40px;

          @media (max-width: ${breakpointsHelper.medium_min_768px}) {
            padding: 40px 48px 48px 48px;
          }

          @media (max-width: ${breakpointsHelper.extra_small_575px}) {
            padding: 40px 16px 40px 16px;
          }
        }
      `;
    } else if ($currentStep === ARN_STEP) {
      return css`
        .vn-modal--content {
          padding: 0;
          max-width: 648px;
        }

        @media (min-width: ${breakpointsHelper.large_min_992px}) {
          .vn-modal__close {
            background-color: #404040;
            opacity: 50%;
            border-radius: 50%;
            svg {
              color: #ffffff;
            }
          }
        }
      `;
    } else if ($currentStep === BILLING_FORM_STEP) {
      return css`
        .vn-modal--content {
          max-width: 920px;
        }
      `;
    }
  }}
`;
