import React, { useEffect, useRef, useState } from 'react';
import CircleIcon from '@veneer/core/dist/scripts/icons/icon_circle';

import {
  Container,
  LeftContainer,
  RightContainer,
  VerticalLine,
  Step
} from './styles';

interface StepperProps {
  stepTitle: string | JSX.Element;
  topics: string[];
  isLastTopic?: boolean;
  index: number;
}

export const Stepper: React.FC<StepperProps> = ({
  stepTitle,
  topics,
  isLastTopic,
  index
}) => {
  const rightContainerRef = useRef<HTMLDivElement>(null);
  const [leftContainerHeight, setLeftContainerHeight] = useState(0);

  useEffect(() => {
    if (rightContainerRef.current) {
      setLeftContainerHeight(rightContainerRef.current.offsetHeight);
    }
  }, [topics, stepTitle]);
  return (
    <Container>
      <LeftContainer>
        <CircleIcon filled size={15} color="black" />
        {!isLastTopic && (
          <VerticalLine style={{ height: leftContainerHeight - 36 }} />
        )}
      </LeftContainer>
      <RightContainer
        ref={rightContainerRef}
        id={`stepper-right-container-${index}`}
      >
        {stepTitle && <Step>{stepTitle}</Step>}
        {topics && (
          <div style={{ listStyle: 'disc' }}>
            <ul>
              {topics.map((topic, index) => (
                <li key={index}>{topic}</li>
              ))}
            </ul>
          </div>
        )}
      </RightContainer>
    </Container>
  );
};
