import styled from 'styled-components';

export const NoScrollViewContainer = styled.div`
  && {
    @media (min-width: 576px) and (max-width: 1199px) {
      display: none;
    }
  }
`;

export const ScrollViewContainer = styled.div`
  && {
    @media (max-width: 575px) or (min-width: 1200px) {
      display: none;
    }
  }
`;

export const PlansContainer = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    @media (max-width: 575px) {
      flex-direction: column;
      align-items: center;
      min-width: 340px;
    }
  }
`;
