import primitives from '@veneer/primitives';
import styled from 'styled-components';

export const Container = styled.div`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const PagesContainer = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 8px;
    align-items: center;

    h2 {
      font-size: 14px;
      font-family: ${primitives.typography.family0};
      color: ${primitives.color.gray12};
      font-weight: 400;
      line-height: 20px;
      margin: 0px;
      margin: 0px 0px 0px 4px;
      word-break: break-all;
    }
  }
`;

export const PriceContainer = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2px;
    align-items: center;

    h2 {
      font-size: 12px;
      font-family: ${primitives.typography.family0};
      color: ${primitives.color.gray9};
      font-weight: 400;
      line-height: 16px;
      margin: 0px 0px 0px 2px;
      word-break: break-all;
    }
  }
`;

export const PagesTitle = styled.h1`
  && {
    font-size: 20px;
    font-family: ${primitives.typography.family0};
    color: ${primitives.color.gray12};
    font-weight: 400;
    line-height: 28px;
    margin: 0px;
    word-break: break-all;
  }
`;

export const PriceTitle = styled.h1`
  && {
    font-size: 16px;
    font-family: ${primitives.typography.family0};
    color: ${primitives.color.gray9};
    font-weight: 400;
    line-height: 24px;
    margin: 0px;
    word-break: break-all;
  }
`;
