import styled from 'styled-components';
import primitives from '@veneer/primitives';
import VeneerCard from '@veneer/core/dist/scripts/card';
import { breakpointsHelper } from '../../../../../common/utils/breakpointsHelper';

export const Container = styled.div`
  && {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
`;

export const TitleContainer = styled.div`
  && {
    h1 {
      font-family: ${primitives.typography.family0};
      color: ${primitives.color.gray12};
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;

      @media (max-width: ${breakpointsHelper.extra_small_575px}) {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
      }
    }
  }
`;

export const Card = styled(VeneerCard)`
  && {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    max-width: 940px;
    border-radius: 12px;
    background-color: ${primitives.color.white};

    @media (max-width: ${breakpointsHelper.medium_min_768px}) {
      max-width: 486px;
    }

    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      padding-bottom: 16px;
    }
  }
`;

export const PlanInfoContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const TonerPlansLink = styled.span`
  && {
    font-family: ${primitives.typography.family0};
    color: ${primitives.color.gray12};
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 24px;

    a {
      cursor: pointer;
    }
  }
`;
