import styled from 'styled-components';
import Card from '@veneer/core/dist/scripts/card';
import { getColorByProgramAndOrder } from '../../../../common/lib/colorHelper';
import primitives from '@veneer/primitives';
import { breakpointsHelper } from '../../../../common/utils/breakpointsHelper';

interface IStyledPlanCard {
  program?: string;
  order?: number;
  totalcards?: number;
  cardwidth?: number;
}

const getColor = (props: IStyledPlanCard) =>
  getColorByProgramAndOrder(props.program, props.order);

export const StyledPlanCard = styled(Card)<IStyledPlanCard>`
  width: 184px;
  height: 210px;
  margin: auto;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;

  .plan-card-items {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    p,
    b {
      font-family: ${primitives.typography.family0};
    }

    > * {
      display: flex;
      flex-direction: column;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-flex: 1;
      flex-grow: 1;
    }
  }

  p {
    background: transparent;
    border: 0;
    font-size: 100%;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
  }

  & {
    .badge-container {
      position: absolute;
      top: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .plan-flavor-section {
      line-height: 24px;
      p {
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        b {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .radio-container {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      > span {
        justify-content: center;
        > span {
          margin: 0;
        }
      }
    }

    .price-pages-container {
      background-color: ${getColor};
      h3 {
        line-height: 24px;
        font-size: 24px;
        text-transform: uppercase;
        margin: 0 0 3px 0;
      }
      p {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        margin: 0;
        color: white;
        text-align: center;
      }
    }
  }
`;

export const StyledPlanCardSMB = styled(StyledPlanCard)<IStyledPlanCard>`
  height: 256px;
  width: 182px;
  padding: 0;
  margin: 0;
  border-width: 0 !important;

  &.smb-regular-plan-card {
    margin-top: 30px;
    border-top-left-radius: ${(props) => (props.order === 0 ? '16px' : '0px')};
    border-bottom-left-radius: ${(props) =>
      props.order === 0 ? '16px' : '0px'};
    border-top-right-radius: ${(props) =>
      props.order === props.totalcards - 1 ? '16px' : '0px'};
    border-bottom-right-radius: ${(props) =>
      props.order === props.totalcards - 1 ? '16px' : '0px'};
  }

  &.smb-recommended-plan-card,
  &.smb-popular-plan-card {
    width: 186px;
    height: 304px;
    border: none;
    margin-top: 30px;

    .smb-card-bottom {
      min-height: 24px;
    }

    .smb-banner {
      background-color: ${getColor};
      line-height: 24px;
      text-align: center;
      height: 24px;
      color: white;
      font-weight: 400;
      font-size: 12px;
      border-radius: 0 0 10px 10px;
      width: 65%;
      font-family: ${primitives.typography.family0};
    }
  }

  .subtitle-large {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  }

  .price-pages-container {
    h3 {
      margin: 0;
      line-height: 40px;
    }
  }
`;

export const StyledMobilePlanCard = styled(Card)<IStyledPlanCard>`
  display: flex;
  border: 1px solid transparent;
  margin-top: 2px;
  && {
    border-top-left-radius: ${(props) => (props.order === 0 ? '16px' : '0px')};
    border-top-right-radius: ${(props) => (props.order === 0 ? '16px' : '0px')};
    border-bottom-left-radius: ${(props) =>
      props.order === props.totalcards - 1 ? '16px' : '0px'};
    border-bottom-right-radius: ${(props) =>
      props.order === props.totalcards - 1 ? '16px' : '0px'};
  }

  & {
    .icon-container {
      display: flex;
      align-items: center;
    }

    .left-container {
      display: flex;
      padding: 20px 25px 20px;
      @media (max-width: 360px) {
        padding: 20px 12px;
      }
      width: 100%;
    }

    .right-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      @media (max-width: 360px) {
        padding: 20px 12px;
      }
    }

    .price-pages-container {
      display: inline-block;
      margin-left: 24px;

      p.price-container {
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        margin: 0;
      }

      p.pages-container {
        margin: 0;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
      }
    }

    .radio-container {
      display: flex;
      justify-content: flex-end;
      padding-left: 30px;
      position: relative;
      top: 3px;
    }
  }
`;

export const StyledMobilePlanCardSmb = styled(Card)<IStyledPlanCard>`
  && {
    flex-grow: 1;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 16px ${(props) => (props.order === 0 ? '16px' : '0px')}
      ${(props) => (props.order === props.totalcards - 1 ? '16px' : '0px')} 16px;
    border-image: initial;
    border-bottom: ${(props) =>
      props.order === props.totalcards - 1
        ? 'none'
        : '2px solid rgb(219, 219, 219)'};
  }
  .card-item {
    width: ${(props) => props.cardwidth - 48}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    font-size: 20px;
    justify-content: space-between;
    border-radius: ${(props) =>
      props.order === 0 || props.order === props.totalcards - 1
        ? '16px'
        : '0px'};
    border-top-left-radius: ${(props) => (props.order === 0 ? '16px' : '0px')};
    border-top-right-radius: ${(props) => (props.order === 0 ? '16px' : '0px')};
    border-bottom-left-radius: ${(props) =>
      props.order === props.totalcards - 1 ? '16px' : '0px'};
    border-bottom-right-radius: ${(props) =>
      props.order === props.totalcards - 1 ? '16px' : '0px'};
    border-left: 16px solid ${getColor};
    @media (min-width: ${breakpointsHelper.small_min_576px}) {
      align-items: flex-end;
    }

    div,
    p,
    b {
      font-family: ${primitives.typography.family0};
    }

    .left-container {
      display: grid;
      grid-template-columns: auto auto;

      color: rgb(2, 122, 174);
      .plan-name {
        font-size: 12px;
        line-height: 16px;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        color: ${primitives.color.gray7};
      }
      .plan-badge-dual-price {
        position: relative;
        margin-bottom: 8px;
        .banner {
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          margin: 0;
          color: rgb(255, 255, 255);
          background-color: ${getColor};
          padding: 0px 8px;
          border-radius: 16px;
          @media (min-width: ${breakpointsHelper.small_min_576px}) {
            padding: 2px 12px 0;
            border-radius: 16px;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      .plan-pages {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        grid-column-start: 1;
        grid-column-end: 2;
        text-align: start;
        color: ${getColor};
      }
    }
    .right-container {
      display: flex;
      flex-direction: column;
      margin-right: 12px;
      align-items: flex-end;

      .plan-name {
        width: fit-content;
        margin: 0 auto;
      }

      .vn-radio-button__icon {
        margin: 0;
        @media (min-width: ${breakpointsHelper.small_min_576px}) {
          margin-right: 5px;
        }
      }
    }

    .plan-price-selector {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .price {
        p {
          margin-right: 14px;
          font-size: 12px;
          text-align: end;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }

    .plan-badge {
      right: 28px;

      .banner {
        font-size: 10px;
        line-height: 16px;
        text-align: center;
        margin: 0;
        color: rgb(255, 255, 255);
        background-color: ${getColor};
        padding: 2px 12px 0;
        border-radius: 16px;
      }
    }
  }
`;
