import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { breakpointsHelper } from '../../../common/utils/breakpointsHelper';

export const CompareYearlyPlanWrapper = styled.div`
  display: flex;
  flex: 45%;
  flex-direction: column;
  cursor: 'none';
  pointer-events: 'none';

  @media (min-width: ${breakpointsHelper.small_min_576px}) {
    &:first-child {
      margin-right: 15px;
    }
  }

  && button {
    margin-top: 0;
    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      max-width: 266px;
    }
    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      max-width: 100%;
      margin-top: 16px;
      flex: 100%;
      &&:first-child {
        margin-right: 0;
      }
    }
  }
`;
export const CompareYearlyPlanCardContainer = styled.div`
  && {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    min-height: 188px;
    border-radius: 8px;
    border: 1px solid ${primitives.color.gray3};

    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      max-width: 266px;
    }
    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      display: none;
    }
  }
`;

export const InfoContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0px 16px;
  }
`;
