import React, { useEffect } from 'react';
import { useGetText } from '../../../hooks/useGetText';
import { Tabs } from './styles';
import { useDispatch } from '../../../hooks/useDispatch';
import { setProgramAction } from '../../../actions/setProgramAction';
import { useConsumerPlansState } from '../../../hooks/useConsumerPlansState';
import { useWindowSize } from '../../../../../common/hooks/useWindowSize';
import { MIN_TABLET_RESOLUTION } from '../../../ConsumerPlanSelector/components/IndividualPlanInfo/common/helpers/resolutionHelper';
import inkPaperImage from '../../../../../../imgs/ink-paper.png';
import inkOnlyImage from '../../../../../../imgs/ink-only.png';
import tonerImage from '../../../../../../imgs/toner-tab-image.png';

interface PlanTabsProps {
  hasInkPaper: boolean;
  tabsContent: {
    id: string;
    content: JSX.Element;
    label: string;
  }[];
}

export const PlanTabs: React.FC<PlanTabsProps> = ({
  hasInkPaper,
  tabsContent
}) => {
  const getText = useGetText();
  const dispatch = useDispatch();
  const { program } = useConsumerPlansState();
  const resolution = useWindowSize();
  const isMobile = resolution < MIN_TABLET_RESOLUTION;

  useEffect(() => {
    if (hasInkPaper) {
      const inkPaperElement =
        document.getElementById('i_ink_paper')?.children[0];
      const inkElement = document.getElementById('i_ink')?.children[0];
      const tonerElement = document.getElementById('i_toner')?.children[0];

      if (inkPaperElement) {
        inkPaperElement.innerHTML = `
            <div style="display: flex; align-items: center;">
              ${renderImages('i_ink_paper')}
              <span>${getText('plans_selector.tabs.ink_paper')}</span>
            </div>
          `;
      }

      if (inkElement) {
        inkElement.innerHTML = `
          <div style="display: flex; align-items: center;">
            ${renderImages('i_ink')}
            <span style="margin-left: 4px;">${getText(
              'plans_selector.tabs.only_ink'
            )}</span>
          </div>
          `;
      }

      if (tonerElement) {
        tonerElement.innerHTML = `
          <div style="display: flex; align-items: center;">
            ${renderImages('i_toner')}
            <span style="margin-left: 4px;">${getText(
              'plans_selector.toner'
            )}</span>
          </div>
          `;
      }
    }
  }, [isMobile]);

  const renderImages = (program: string) => {
    if (program === 'i_ink_paper' && !isMobile) {
      return `
      <img
      src="${inkPaperImage}"
      width="48"
      height="48"
      alt="Photo of Hp paper and ink cartridges"
      data-testid="ink-paper-image-tab"
    />`;
    }
    if (program === 'i_ink' && !isMobile) {
      return `
        <img
          src="${inkOnlyImage}"
          width="38"
          height="39"
          alt="Photo of Hp ink cartridges"
          data-testid="ink-only-image-tab"
        />`;
    }
    if (program === 'i_toner' && !isMobile) {
      return `
        <img
          src="${tonerImage}"
          width="48"
          height="48"
          alt="Photo of Hp ink cartridges"
          data-testid="ink-only-image-tab"
        />`;
    }
    return '';
  };

  const handleChangeProgram = (id) => {
    if (dispatch) {
      dispatch(setProgramAction(id));
    }
  };

  return (
    <Tabs
      controlId="default"
      mode="extended"
      onChangeTab={(id) => handleChangeProgram(id)}
      selectedTabId={program}
      data-linkid="ii_plan_selector"
      tabs={tabsContent}
      type="online"
      $hasInkPaperTab={hasInkPaper}
    />
  );
};
