import React, { useEffect } from 'react';
import {
  Container,
  MoreInfo,
  PlansContainer,
  SubtitleContainer
} from './styles';
import { useConsumerPlansState } from '../../../hooks/useConsumerPlansState';
import { useGetText } from '../../../hooks/useGetText';
import { PLAN_PROGRAMMES } from '../../../../../common/constants';
import { Plan } from '../../../types/consumerPlansState';
import { DesktopPlanCard } from './DesktopPlanCard';
import { useWindowSize } from '../../../../../common/hooks/useWindowSize';
import { MobilePlanCard } from './MobilePlanCard';

interface PlanCardsProps {
  plans: Plan[];
  isInkPaper?: boolean;
  disableSelectPlanButton: (isDisabled: boolean) => void;
  setShowLearnMore: (state: boolean) => void;
}

export const PlanCards: React.FC<PlanCardsProps> = ({
  plans,
  isInkPaper,
  disableSelectPlanButton,
  setShowLearnMore
}) => {
  const getText = useGetText();
  const {
    program,
    defaultPlanGUIDInk,
    defaultPlanGUIDToner,
    selectedPlan,
    showPlanProgramTabs,
    renderLearnMore
  } = useConsumerPlansState();
  const resolution = useWindowSize();
  const isDesktop = resolution > 970;
  const isConsumerInk =
    program === PLAN_PROGRAMMES.INSTANT_INK ||
    program === PLAN_PROGRAMMES.INSTANT_INK_PAPER;
  const defaultPlanGUID = isConsumerInk
    ? defaultPlanGUIDInk
    : defaultPlanGUIDToner;
  const tagName = getText('plans_selector.common.recommended');

  useEffect(() => {
    if (isInkPaper) {
      disableSelectPlanButton(
        !plans.some((plan) => `${plan.guid}_i_ink_paper` === selectedPlan.guid)
      );
    } else {
      disableSelectPlanButton(
        !plans.some((plan) => plan.guid === selectedPlan.guid)
      );
    }
  }, [selectedPlan]);

  const renderSubtitle = () => {
    if (program === 'i_ink_paper') {
      return (
        <>
          <span>{getText('plans_selector.never_run_out')}</span>
          {renderLearnMore && (
            <MoreInfo
              data-testid="plans-selector-plan-cards-more-info"
              onClick={() => {
                setShowLearnMore(true);
              }}
            >
              {getText('plans_selector.more_info')}
            </MoreInfo>
          )}
        </>
      );
    } else if (program === 'i_ink') {
      return (
        <span>
          {getText('plans_selector.never_run_out_of_hp_original_ink')}
        </span>
      );
    } else if (program === 'i_toner') {
      return (
        <span>
          {getText('plans_selector.never_run_out_of_hp_original_toner')}
        </span>
      );
    }
  };

  return (
    <Container>
      <SubtitleContainer
        data-testid="plans-selector-plan-cards-subtitle"
        isInsideTab={showPlanProgramTabs}
      >
        {renderSubtitle()}
      </SubtitleContainer>
      <PlansContainer>
        {plans.map((plan, index) => {
          return isDesktop ? (
            <DesktopPlanCard
              index={index}
              key={index}
              plan={plan}
              defaultPlanGUID={defaultPlanGUID}
              tagName={tagName}
              isInkPaper={isInkPaper}
            />
          ) : (
            <MobilePlanCard
              index={index}
              key={index}
              plan={plan}
              defaultPlanGUID={defaultPlanGUID}
              tagName={tagName}
              isInkPaper={isInkPaper}
            />
          );
        })}
      </PlansContainer>
    </Container>
  );
};
