import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const PlanDetailsContainer = styled.div`
  margin-top: 24px;
  && {
    span {
      font-size: 18px;
      font-weight: 700;
      color: ${primitives.color.gray12};
      line-height: 20px;
      font-family: ${primitives.typography.family0};
      margin-bottom: 8px;
    }

    ul {
      margin-top: 8px;
      padding-inline-start: 25px;
      list-style: disc;
    }

    li {
      font-size: 16px;
      font-weight: 400;
      color: ${primitives.color.gray12};
      line-height: 24px;
      font-family: ${primitives.typography.family0};
    }
  }
`;
