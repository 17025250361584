export const colorTokens = {
  colorPurple0: '#f8e6ff',
  colorPurple1: '#e3b3fb',
  colorPurple2: '#ce84f4',
  colorPurple3: '#bc58eb',
  colorPurple4: '#a832df',
  colorPurple5: '#950ed0',
  colorPurple6: '#8400be',
  colorPurple7: '#7300a9',
  colorPurple8: '#60008e',
  colorPurple9: '#4b0070',
  colorPurple10: '#350052',
  colorPink0: '#ffe6fa',
  colorPink1: '#fcb4ee',
  colorPink2: '#f385e1',
  colorPink3: '#eb5ad2',
  colorPink4: '#df36c3',
  colorPink5: '#cf15b2',
  colorPink6: '#be00a1',
  colorPink7: '#a9008d',
  colorPink8: '#8e0076',
  colorPink9: '#70005c',
  colorPink10: '#510043',
  colorRed0: '#ffe6e5',
  colorRed1: '#fbb5b6',
  colorRed2: '#f48787',
  colorRed3: '#eb5e5e',
  colorRed4: '#df3d3d',
  colorRed5: '#cf2323',
  colorRed6: '#be1313',
  colorRed7: '#a90b0a',
  colorRed8: '#8e0302',
  colorRed9: '#700000',
  colorRed10: '#520000',
  colorDarkOrange0: '#ffe7d9',
  colorDarkOrange1: '#fbc7ac',
  colorDarkOrange2: '#f4a97f',
  colorDarkOrange3: '#eb8d5b',
  colorDarkOrange4: '#df733b',
  colorDarkOrange5: '#cf5d22',
  colorDarkOrange6: '#be4813',
  colorDarkOrange7: '#a93409',
  colorDarkOrange8: '#8e2203',
  colorDarkOrange9: '#701600',
  colorDarkOrange10: '#520b00',
  colorOrange0: '#ffe5cc',
  colorOrange1: '#ffdebc',
  colorOrange2: '#fdd1a5',
  colorOrange3: '#f9c086',
  colorOrange4: '#f2a95d',
  colorOrange5: '#e68b2d',
  colorOrange6: '#d06702',
  colorOrange7: '#b55500',
  colorOrange8: '#954300',
  colorOrange9: '#713000',
  colorOrange10: '#4d1f00',
  colorYellow0: '#fff6da',
  colorYellow1: '#ffeeb5',
  colorYellow2: '#ffe78f',
  colorYellow3: '#ffde6a',
  colorYellow4: '#fed64d',
  colorYellow5: '#ffce33',
  colorYellow6: '#f1c029',
  colorYellow7: '#e0b021',
  colorYellow8: '#cfa11c',
  colorYellow9: '#bb8f19',
  colorYellow10: '#a67f19',
  colorLime0: '#fcffcf',
  colorLime1: '#f4fe8c',
  colorLime2: '#ecf863',
  colorLime3: '#e4f148',
  colorLime4: '#d8e838',
  colorLime5: '#cadb2b',
  colorLime6: '#becd22',
  colorLime7: '#afbb1e',
  colorLime8: '#9ba519',
  colorLime9: '#858b15',
  colorLime10: '#6d7111',
  colorLightGreen0: '#f0ffd9',
  colorLightGreen1: '#deffad',
  colorLightGreen2: '#c7f77f',
  colorLightGreen3: '#ade855',
  colorLightGreen4: '#90d034',
  colorLightGreen5: '#72b21a',
  colorLightGreen6: '#508c0a',
  colorLightGreen7: '#366c04',
  colorLightGreen8: '#235602',
  colorLightGreen9: '#184700',
  colorLightGreen10: '#114000',
  colorGreen0: '#e3ffe3',
  colorGreen1: '#b9fdbb',
  colorGreen2: '#8ef291',
  colorGreen3: '#67df6a',
  colorGreen4: '#47c34a',
  colorGreen5: '#309f2f',
  colorGreen6: '#1c7a17',
  colorGreen7: '#135b08',
  colorGreen8: '#104506',
  colorGreen9: '#103805',
  colorGreen10: '#123302',
  colorTurquoise0: '#e7fffb',
  colorTurquoise1: '#b7f9ee',
  colorTurquoise2: '#8af0df',
  colorTurquoise3: '#63e4d0',
  colorTurquoise4: '#41d6bd',
  colorTurquoise5: '#29c3a8',
  colorTurquoise6: '#1bb3a3',
  colorTurquoise7: '#0c9f98',
  colorTurquoise8: '#068686',
  colorTurquoise9: '#03636a',
  colorTurquoise10: '#01454d',
  colorHpBlue0: '#ebf9ff',
  colorHpBlue1: '#caf3ff',
  colorHpBlue2: '#9fe5ff',
  colorHpBlue3: '#6dd0fb',
  colorHpBlue4: '#3ab4eb',
  colorHpBlue5: '#0096d6',
  colorHpBlue6: '#0278ab',
  colorHpBlue7: '#035c84',
  colorHpBlue8: '#014667',
  colorHpBlue9: '#013650',
  colorHpBlue10: '#002b40',
  colorBlue0: '#e7e5ff',
  colorBlue1: '#b6b3fa',
  colorBlue2: '#8783f2',
  colorBlue3: '#5e57e8',
  colorBlue4: '#3e30da',
  colorBlue5: '#250ec8',
  colorBlue6: '#1600b6',
  colorBlue7: '#0e009f',
  colorBlue8: '#070081',
  colorBlue9: '#030061',
  colorBlue10: '#010040',
  colorGray0: '#f8f8f8',
  colorGray1: '#f1f1f1',
  colorGray2: '#ebebeb',
  colorGray3: '#dbdbdb',
  colorGray4: '#adadad',
  colorGray5: '#737373',
  colorGray6: '#585858',
  colorGray7: '#404040',
  colorGray8: '#363636',
  colorGray9: '#2b2b2b',
  colorGray10: '#212121',
  colorBlack: '#000000',
  colorWhite: '#ffffff',
  colorHighContrastYellow: '#fff700',
  colorHighContrastBlue: '#00d3ff',
  colorHighContrastPink: '#fe6aff',
  colorHighContrastGray: '#adadad',
  colorHighContrastEigengrau: '#16141d'
};
