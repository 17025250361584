import styled from 'styled-components';
import VeneerTabs from '@veneer/core/dist/scripts/tabs';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlanProgramSelectorContainer = styled.div`
  display: block;
  text-align: center;
  width: 100%;
  max-width: 991px;

  nav {
    box-shadow: none;
  }
`;

export const Tabs = styled(VeneerTabs)`
  div[role='tablist'] {
    justify-content: center;
  }
`;
