import React from 'react';
import { ContentContainer, InfoContainer } from './styles';
import { TitleAndSubtitle } from '../common/components/TitleAndSubtitle';
import { TrialInfo } from '../common/components/TrialInfo';
import { PlanDetails } from '../common/components/PlanDetails';
import { PlanImage } from '../common/components/PlanImage';
import { PlanInfoButtons } from '../common/components/PlanInfoButtons';

interface DesktopPlanInfoProps {
  setShowIndividualPlanInfo: (state: boolean) => void;
}

export const DesktopPlanInfo: React.FC<DesktopPlanInfoProps> = ({
  setShowIndividualPlanInfo
}) => {
  return (
    <div data-testid="plans-desktop-plan-info">
      <ContentContainer>
        <InfoContainer>
          <TitleAndSubtitle />
          <TrialInfo />
          <PlanDetails />
        </InfoContainer>
        <PlanImage />
      </ContentContainer>
      <PlanInfoButtons setShowIndividualPlanInfo={setShowIndividualPlanInfo} />
    </div>
  );
};
