import React from 'react';
import IconCheckmark from '@veneer/core/dist/scripts/icons/icon_checkmark';
import {
  Container,
  AttributeContainer,
  Attribute,
  AttributeText
} from './styles';

interface PlanAttributesProps {
  attributes: string[];
}

export const PlanAttributes: React.FC<PlanAttributesProps> = ({
  attributes
}) => {
  return (
    <Container data-testid="plan-attributes-info-container">
      <AttributeContainer data-testid="attribute-container">
        {attributes.map((attr, index) => (
          <Attribute key={index}>
            <IconCheckmark /> <AttributeText>{attr}</AttributeText>
          </Attribute>
        ))}
      </AttributeContainer>
    </Container>
  );
};
